"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKIPPED_AUTHENTICATION_SESSION = void 0;
/**
 * Session definition indicating "authentication" succeeded while
 * `BUILDER_SKIP_AUTHENTICATION` is set. This fake sessions is intended only for
 * use during local development and should not be accessible when in a deployed
 * environment.
 */
exports.SKIPPED_AUTHENTICATION_SESSION = {
    sessionId: 'skipped-auth-session',
    serviceId: 'skipped-auth-serviceId',
    // @ts-expect-error this is intentionally not a `ServiceType`
    serviceType: 'skipped-auth',
    token: 'not-a-token',
};
