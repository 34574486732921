"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestByNameIndex = exports.AccessCodeByCodeIndex = void 0;
/**
 * Name of the secondary index which allows looking up an access code by showId
 * and access code value directly.
 */
exports.AccessCodeByCodeIndex = 'AccessCodeByCode';
/**
 * Name of the secondary index which allows looking up records by the
 * `_sortGuestName` * and `ParentId` fields.
 */
exports.GuestByNameIndex = 'GuestByGuestSortName';
