"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isHostname = void 0;
// Copied and modified from ajv-formats
// See https://github.com/ajv-validator/ajv-formats/blob/4ca86d21bd07571a30178cbb3714133db6eada9a/src/formats.ts#L67
const Hostname = /^(?=.{1,253}\.?$)[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[-0-9a-z]{0,61}[0-9a-z])?)*\.?$/i;
function isHostname(value) {
    return Hostname.test(value);
}
exports.isHostname = isHostname;
