"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMuxAssetMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
exports.CreateMuxAssetMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        assetId: typebox_1.Type.String({
            title: 'Asset ID',
            description: 'Asset ID of the file to be transcoded via mux.',
        }),
    }),
    'detail-type': typebox_1.Type.Literal('CreateMuxAsset'),
    source: message_source_1.AssetManagmentSource,
    traceId: tracing_1.TraceId,
});
