"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentInstanceJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const content_instance_model_1 = require("./content-instance-model");
function fromJSON(item, mode) {
    const isContentInstanceItem = (0, json_schema_1.createValidator)(content_instance_model_1.ContentInstanceJson);
    if (isContentInstanceItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            name: item.name,
            slug: item.slug,
            contentModelId: item.contentModelId,
            siteId: item.siteId,
            status: item.status,
            value: item.value,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isContentInstanceItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `ContentInstance`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `ContentInstance`.
 */
function toJSON(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        name: record.name,
        slug: record.slug,
        contentModelId: record.contentModelId,
        siteId: record.siteId,
        status: record.status,
        value: record.value,
    };
}
exports.ContentInstanceJsonMapper = {
    fromJSON,
    toJSON,
};
