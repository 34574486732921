"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleJson = exports.ModuleModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of attendee data records.
 */
exports.ModuleModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    /**
     * Identifies the site to which this module is attached (via page) for
     * non-staff modules
     * @deprecated `siteID` can get out of sync with `pageID`, use the
     * relationship from `pageID` instead.
     *
     * FIXME: This should be removed because it could get out of sync with
     * `pageID`
     */
    siteID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    /**
     * Identifies the page to which this module is attached for non-staff modules
     */
    pageID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    pageIndex: (0, model_helpers_1.Nullable)(typebox_1.Type.Integer()),
    /**
     * Identifies the `core` which has field data for this module
     */
    coreID: typebox_1.Type.String({ format: 'uuid' }),
    /**
     * Identifies the `core` which is the direct parent of this module
     * irrespective of group membership
     */
    parentID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    parentSlotSlug: (0, model_helpers_1.Nullable)(typebox_1.Type.String()),
    parentSlotIndex: (0, model_helpers_1.Nullable)(typebox_1.Type.Integer()),
    variables: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    adminUI: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    /**
     * Path is used for modules in slots to identify the staff/boss slot in which
     * this module resides.
     */
    path: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' })),
    /**
     * Identifies the `core` which is the "boss" core for this module
     */
    groupID: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'uuid' })),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.ModuleJson = exports.ModuleModel;
