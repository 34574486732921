"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Watermark = void 0;
const typebox_1 = require("@sinclair/typebox");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
/**
 * Defines the JSON serialized shape of `watermark` data records.
 */
exports.Watermark = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    logUri: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    configurationId: typebox_1.Type.Integer(),
    strength: typebox_1.Type.Integer(),
    status: typebox_1.Type.String(),
    message: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    streamId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    assetId: typebox_1.Type.String(),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
