"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isIPv4 = void 0;
// Copied and modified from @sinclair/typebox/format
// See https://github.com/sinclairzx81/typebox/tree/83e05bb43ca79cb22c9efc27e2e16caf7cb6a0e5/example/formats
const IPv4 = /^(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.){3}(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/;
/**
 * `[ajv-formats]` IPv4 address according to dotted-quad ABNF syntax as defined in [RFC 2673, section 3.2](http://tools.ietf.org/html/rfc2673#section-3.2)
 * @example `192.168.0.1`
 */
function isIPv4(value) {
    return IPv4.test(value);
}
exports.isIPv4 = isIPv4;
