"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Recipient = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.Recipient = typebox_1.Type.Object({
    name: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Recipient Name',
        description: 'The name part in "First Last <example@example.com>"',
        examples: ['First Last', 'ScreenName'],
    })),
    email: typebox_1.Type.String({
        title: 'Recipient Email',
        description: 'The email part in "First Last <example@example.com>"',
        examples: ['example@example.com'],
    }),
});
