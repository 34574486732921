import {DynamicLayoutComponent, PageData} from '@backstage/ui-render';
import {Fragment, VFC} from 'react';
import {Helmet} from 'react-helmet-async';

export const PageRoute: VFC<PageRouteProps> = (props) => {
  const {page} = props;
  const modules = page?.modules ?? [];

  return (
    <Fragment>
      <Helmet htmlAttributes={{'data-pageid': page.id}}>
        <title>{page.title}</title>
      </Helmet>
      <DynamicLayoutComponent components={modules} />
    </Fragment>
  );
};

interface PageRouteProps {
  showId?: string;
  page: PageData;
}
