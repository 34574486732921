"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectionJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const ConnectionStatus = typebox_1.Type.Union([
    typebox_1.Type.Literal('ACTIVE', {
        description: 'Connection was recently used for sending or receiving messages',
    }),
    typebox_1.Type.Literal('DISCONNECTED', { description: 'Connection was terminated' }),
    typebox_1.Type.Literal('INACTIVE', {
        description: 'Connection has not sent or received a message recently',
    }),
], { $id: 'ConnectionStatusModel' });
/**
 * Defines the JSON serialized shape of connection records.
 */
exports.ConnectionJson = typebox_1.Type.Object({
    /** Unique identifier for the socket connection */
    id: typebox_1.Type.String({
        description: 'The unique identifier for the connection provided by the connection manager.',
    }),
    /** Unique identifier for the show associated with the connection */
    showId: typebox_1.Type.String({ format: 'uuid' }),
    /** The last time the connection sent a heartbeat message */
    lastHeartBeatAt: typebox_1.Type.String({ format: 'date-time' }),
    /** Status of the connection */
    status: ConnectionStatus,
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ConnectionModel' });
