import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { isContent, isLayout } from '../types/dynamicComponent.types';
import { ComponentController } from './componentController';
import { LayoutController } from './layoutController';
const Root = styled.div `
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;
export const DynamicLayoutComponent = (props) => {
    const itemsLength = 100 / props.components.length;
    return (_jsx(Root, { className: "layout", children: props.components.map((component) => {
            const enableFlexShorthand = isLayout(component) &&
                'autoLayout' in component.props &&
                component.props.autoLayout;
            const flexShorthand = enableFlexShorthand
                ? `flex: 1 1 ${itemsLength}%;`
                : '';
            const componentStyle = component.style
                ? `${component.style}; ${flexShorthand}`
                : `${flexShorthand}`;
            if (isLayout(component)) {
                return (_jsx(LayoutController, { ...component, style: componentStyle }, component.id));
            }
            else if (isContent(component)) {
                return (_jsx(ComponentController, { ...component, style: componentStyle }, component.id));
            }
            else {
                return _jsx(Fragment, {}, component.id);
            }
        }) }));
};
