"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimezoneName = exports.TimezoneLabels = void 0;
const typebox_1 = require("@sinclair/typebox");
/**
 * The timezones and their aliases supported by backstage. `name` should be a
 * zone name in the iana time zone database. Aliases should not be duplicated
 * across zone names. If no aliases are provided the zone name will be used
 * instead.
 * @see https://www.iana.org/time-zones
 */
const zones = [
    { name: 'America/New_York', aliases: ['New York, US'] },
    { name: 'America/Chicago', aliases: ['Chicago, US'] },
    { name: 'America/Denver', aliases: ['Denver, US'] },
    { name: 'America/Los_Angeles', aliases: ['Los Angeles, US'] },
    { name: 'America/Phoenix', aliases: ['Phoenix, US'] },
    { name: 'Etc/GMT', aliases: ['GMT'] },
    { name: 'Etc/UTC', aliases: ['UTC'] },
    { name: 'Europe/London', aliases: ['London, UK'] },
    { name: 'Europe/Paris', aliases: ['Paris, FR'] },
    { name: 'Japan', aliases: [] },
];
exports.TimezoneLabels = zones.reduce((memo, zone) => {
    if (zone.aliases.length === 0) {
        memo[zone.name] = [zone.name];
    }
    else {
        memo[zone.name] = zone.aliases.slice();
    }
    return memo;
}, {});
exports.TimezoneName = typebox_1.Type.Union(zones.map((zone) => typebox_1.Type.Literal(zone.name)), { $id: 'TimezoneNameSchema' });
