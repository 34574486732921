"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveSiteDistributionUpdatedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * The 'LiveSiteDistribution Updated' message is intended to inidicate the
 * "parent" CloudFront Distribution upon which deployed sites are based has
 * been updated.
 */
exports.LiveSiteDistributionUpdatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * Domain name of the S3 bucket which is the origin for live site
         * Distributions. Used to identified the "child" Distributions.
         */
        bucketDomainName: typebox_1.Type.String({
            description: 'Domain name of the S3 bucket origin for the Distribution',
        }),
        /**
         * Id of the CloudFront Distribution which is used as the base for updating
         * other Distributions. Identifies the "parent" Distribution.
         */
        distributionId: typebox_1.Type.String({
            description: 'Id of the "parent" CloudFront Distribution',
        }),
    }),
    source: message_source_1.CloudFormationSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('LiveSiteDistribution Updated'),
});
