"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Organization = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const OrganizationTier = typebox_1.Type.Union([
    typebox_1.Type.Literal('sandbox', {
        description: 'Free tier with limited access to features',
    }),
    typebox_1.Type.Literal('starter', {
        description: 'Entry level tier with access to publishing',
    }),
    typebox_1.Type.Literal('business', {
        description: 'Mid level tier with access to the majority of features',
    }),
    typebox_1.Type.Literal('enterprise', {
        description: 'Top level tier with access to all features',
    }),
]);
/**
 * Defines the JSON serialized shape of site data records.
 */
exports.Organization = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    tier: OrganizationTier,
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'OrganizationModel' });
