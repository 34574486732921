"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const timezone_1 = require("../../data/timezone");
const timestamp_json_1 = require("../timestamp-json");
const AccountPreferences = typebox_1.Type.Object({ timezone: typebox_1.Type.Optional(timezone_1.TimezoneName) }, { $id: 'AccountPreferencesModel' });
/**
 * Defines the JSON serialized shape of account records.
 */
exports.AccountModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    email: typebox_1.Type.String({ format: 'email' }),
    preferences: AccountPreferences,
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'AccountModel' });
