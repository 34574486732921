import {useAnalyticsToken} from '@backstage-components/guest-consent-provider';
import {isAnalyticsBlockListDomain} from '@backstage/utils/domain-helpers';
import {useEffect, useState} from 'react';
import {config} from '../../config';

interface AnalyticsProps {
  /** URL to which instructions will be transmitted as analytics */
  endpoint: string;
  /** Token used to identify a specific guest anonymously */
  token?: string;
}

type AnalyticsStatus = 'anonymous' | 'default' | 'disabled';

/**
 * Read analytics token from storage, if one does not exist create one. If the
 * current domain should not send analytics provides `undefined`.
 */
export function useAnalytics(
  status: AnalyticsStatus = 'default'
): AnalyticsProps | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const token = useAnalyticsToken();
  const endpoint = `${config.endpointBase}/attendee/analytics`;
  const [result, setResult] = useState({endpoint, token});
  useEffect(() => {
    if (status === 'anonymous') {
      setResult((current) =>
        current.token === undefined ? current : {endpoint, token: undefined}
      );
    } else {
      setResult((current) =>
        current.token === token ? current : {endpoint, token}
      );
    }
  }, [endpoint, status, token]);
  if (isAnalyticsBlockListDomain(config, location) || status === 'disabled') {
    return undefined;
  } else {
    return result;
  }
}
