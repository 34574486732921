"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTransactionJson = exports.EmailTransactionModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const recipient_model_1 = require("./recipient-model");
exports.EmailTransactionModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    recipient: recipient_model_1.Recipient,
    status: typebox_1.Type.Union([
        typebox_1.Type.Literal('error'),
        typebox_1.Type.Literal('pending'),
        typebox_1.Type.Literal('sent'),
    ]),
    templateId: typebox_1.Type.String(),
    triggerId: typebox_1.Type.String(),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.EmailTransactionJson = exports.EmailTransactionModel;
