"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscodeAssetMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
exports.TranscodeAssetMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        assetId: typebox_1.Type.String({
            title: 'Asset ID',
            description: 'Asset ID of asset to be transcoded',
        }),
        uri: typebox_1.Type.Union([
            typebox_1.Type.String({
                title: 'Asset URI',
                description: 'URI of Asset in S3',
            }),
            typebox_1.Type.Null(),
        ]),
        processDash: typebox_1.Type.Boolean({
            title: 'Process DASH Boolean',
            description: 'Whether or not to process asset as DASH',
        }),
        processHLS: typebox_1.Type.Boolean({
            title: 'Process HLS Boolean',
            description: 'Whether or not to process asset as HLS',
        }),
        processCMAF: typebox_1.Type.Boolean({
            title: 'Process CMAF Boolean',
            description: 'Whether or not to process asset as CMAF',
        }),
    }),
    'detail-type': typebox_1.Type.Literal('TranscodeAsset'),
    source: message_source_1.AssetManagmentSource,
    traceId: tracing_1.TraceId,
});
