"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const site_resource_1 = require("./site-resource");
const site_settings_1 = require("./site-settings");
/**
 * Defines the JSON serialized shape of site data records.
 */
exports.SiteJson = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    organizationId: typebox_1.Type.String({ format: 'uuid' }),
    variables: (0, model_helpers_1.JsonObject)(),
    meta: (0, model_helpers_1.JsonObject)(),
    resources: site_resource_1.SiteResourceData,
    status: typebox_1.Type.Union([typebox_1.Type.Literal('active'), typebox_1.Type.Literal('archived')]),
    settings: site_settings_1.SiteSettings,
    css: typebox_1.Type.String(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'SiteModel' });
