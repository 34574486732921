"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateLimitRequestJson = exports.RateLimitRequestModel = void 0;
const typebox_1 = require("@sinclair/typebox");
/**
 * Defines the JSON serialized shape of rate limit request data records.
 */
exports.RateLimitRequestModel = typebox_1.Type.Object({
    CacheKey: typebox_1.Type.String(),
    SortKey: typebox_1.Type.String(),
    ExpiresAt: typebox_1.Type.Number(),
    RequestId: typebox_1.Type.String(),
});
/**
 * Defines the JSON serialized shape of rate limit request data records.
 */
exports.RateLimitRequestJson = typebox_1.Type.Union([exports.RateLimitRequestModel]);
