"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetLanguageLabels = void 0;
// Labels for languages based on their RFC 5646 codes
// https://www.rfc-editor.org/rfc/rfc5646.html
exports.AssetLanguageLabels = {
    ar: {
        label: 'العربية',
        uiLabel: 'Arabic',
    },
    en: {
        label: 'English',
        uiLabel: 'English',
    },
    'en-US': {
        label: 'English',
        uiLabel: 'English',
        labelOnly: true,
    },
    es: {
        label: 'Español',
        uiLabel: 'Spanish',
    },
    'es-MX': {
        label: 'Español (Latinoamérica)',
        uiLabel: 'Spanish (Latin America)',
    },
    'es-ES': {
        label: 'Español',
        uiLabel: 'Spanish',
        labelOnly: true,
    },
    et: {
        label: 'Eesti',
        uiLabel: 'Estonian',
    },
    de: {
        label: 'Deutsch',
        uiLabel: 'German',
    },
    hr: {
        label: 'Hrvatski',
        uiLabel: 'Croatian',
    },
    it: {
        label: 'Italiano',
        uiLabel: 'Italian',
    },
    ro: {
        label: 'Română',
        uiLabel: 'Romanian',
    },
    fr: {
        label: 'Français',
        uiLabel: 'French',
    },
    lv: {
        label: 'Latviešu',
        uiLabel: 'Latvian',
    },
    mk: {
        label: 'Македонски',
        uiLabel: 'Macedonian',
    },
    pt: {
        label: 'Português',
        uiLabel: 'Portugese',
    },
    'pt-BR': {
        label: 'Português (Brasil)',
        uiLabel: 'Portugese (Brazilian)',
    },
    'pt-PT': {
        label: 'Português',
        uiLabel: 'Portugese',
        labelOnly: true,
    },
    sk: {
        label: 'Slovenčina',
        uiLabel: 'Slovenian',
    },
    'sr-SP': {
        label: 'Srpski',
        uiLabel: 'Serbian (Latin)',
    },
    nb: {
        label: 'Norsk',
        uiLabel: 'Norwegian',
    },
    el: {
        label: 'Ελληνικά',
        uiLabel: 'Greek',
    },
    sv: {
        label: 'Svenska',
        uiLabel: 'Swedish',
    },
    hu: {
        label: 'Magyar',
        uiLabel: 'Hungarian',
    },
    da: {
        label: 'Dansk',
        uiLabel: 'Danish',
    },
    nl: {
        label: 'Nederlands',
        uiLabel: 'Dutch',
    },
    fi: {
        label: 'Suomi',
        uiLabel: 'Finish',
    },
    ja: {
        label: '日本語',
        uiLabel: 'Japanese',
    },
    ko: {
        label: '한국어',
        uiLabel: 'Korean',
    },
    he: {
        label: 'עברית‎',
        uiLabel: 'Hebrew',
    },
    pl: {
        label: 'Polski',
        uiLabel: 'Polish',
    },
    id: {
        label: 'Bahasa Indonesia',
        uiLabel: 'Indonesian',
    },
    ms: {
        label: 'Bahasa Melayu',
        uiLabel: 'Malay',
    },
    vi: {
        label: 'tiếng Việt',
        uiLabel: 'Vietnamese',
    },
    th: {
        label: 'ไทย',
        uiLabel: 'Thai',
    },
    'zh-CN': {
        label: '简体中文',
        uiLabel: 'Simplified Chinese',
    },
    // This should be `zh-Hans` but a MediaConvert bug limits the use of Region and Script subcodes
    // https://linear.app/lcdbackstage/issue/BSG-1404/update-zh-label-to-zh-hans-in-video-player-language-labels
    zh: {
        label: '繁體中文',
        uiLabel: 'Traditional Chinese',
    },
};
