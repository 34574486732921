"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const organization_model_1 = require("./organization-model");
function fromJson(item, mode) {
    const isOrganizationItem = (0, json_schema_1.createValidator)(organization_model_1.Organization);
    if (isOrganizationItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            name: item.name,
            tier: item.tier,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isOrganizationItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Organization`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Organization`.
 */
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        name: record.name,
        tier: record.tier,
    };
}
exports.OrganizationJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
