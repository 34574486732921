"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transfer = void 0;
const typebox_1 = require("@sinclair/typebox");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
/**
 * Defines the JSON serialized shape of `transfer` data records.
 */
exports.Transfer = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    transferId: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    transferStatus: typebox_1.Type.String(),
    assetId: typebox_1.Type.String(),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
