"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainManagementSource = exports.SitePublishSource = exports.ShowPublishSource = exports.LiveSiteViewSource = exports.AssetManagmentSource = exports.CloudFormationSource = exports.AssetManagmentApiSource = exports.ApiSource = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.ApiSource = typebox_1.Type.Literal('@backstage/messaging-api', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
exports.AssetManagmentApiSource = typebox_1.Type.Literal('@backstage/asset-managment-api', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
/**
 * Represents a message originating as the result of a CloudFormation Stack (or
 * NestedStack) deployment
 */
exports.CloudFormationSource = typebox_1.Type.Literal('@aws/cloudformation', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
/** Represents a message originating from the "Asset Management" service */
exports.AssetManagmentSource = typebox_1.Type.Literal('@backstage-services/asset-management', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
/** Represents a message originating from the "Live Site View" service */
exports.LiveSiteViewSource = typebox_1.Type.Literal('@backstage/live-site-view', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
exports.ShowPublishSource = typebox_1.Type.Literal('@backstage-services/show-publish', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
exports.SitePublishSource = typebox_1.Type.Literal('@backstage-services/site-publish', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
exports.DomainManagementSource = typebox_1.Type.Literal('@backstage-services/domain-management', {
    title: 'Message Source',
    description: 'Name used to identify the source of the `Message`s being published.',
});
