"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSiteVariableLookup = void 0;
const createSiteVariableLookup = (siteVars) => {
    const obj = {};
    (siteVars ?? []).forEach((sv) => {
        let value = 'value' in sv ? sv.value : undefined;
        if ('source' in sv) {
            value = sv.source;
        }
        else if ('switchValue' in sv) {
            value = sv.switchValue;
        }
        else if ('numberValue' in sv) {
            value = sv.numberValue;
        }
        else if ('videoSource' in sv) {
            value = sv.videoSource;
        }
        if (typeof value !== 'undefined') {
            obj[sv.slug] = value;
        }
    });
    return obj;
};
exports.createSiteVariableLookup = createSiteVariableLookup;
