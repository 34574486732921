"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nullable = exports.Maybe = exports.JsonValue = exports.JsonObject = void 0;
const typebox_1 = require("@sinclair/typebox");
/**
 * Uses the same JSON Schema definition as `Type.Unknown` but "masks" it as
 * `JSONObject`.
 */
const JsonObject = (options) => typebox_1.Type.Unsafe(typebox_1.Type.Unknown(options));
exports.JsonObject = JsonObject;
/**
 * Uses the same JSON Schema definition as `Type.Unknown` but "masks" it as
 * `JSONValue`.
 */
const JsonValue = (options) => typebox_1.Type.Unsafe(typebox_1.Type.Unknown(options));
exports.JsonValue = JsonValue;
/** Modifies an object property to be optional and nullable */
function Maybe(item, options) {
    return typebox_1.Type.Optional(typebox_1.Type.Union([item, typebox_1.Type.Null(options)]));
}
exports.Maybe = Maybe;
/** Modifies an object property to be nullable */
function Nullable(item, options) {
    return typebox_1.Type.Union([item, typebox_1.Type.Null(options)]);
}
exports.Nullable = Nullable;
