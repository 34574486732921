"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const BaseGuestModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    attendeeId: typebox_1.Type.String({ format: 'uuid' }),
    avatar: typebox_1.Type.Optional((0, model_helpers_1.Nullable)(typebox_1.Type.String({
        format: 'uri',
        description: 'The URL of the image to use as a guests avatar',
    }))),
    attendeeAccessCode: typebox_1.Type.String({
        description: 'The value of the access code an attendee uses to login, may or may not be encrypted',
    }),
    attendeeAccessCodeIV: (0, model_helpers_1.Nullable)(typebox_1.Type.String({
        description: 'If provided, indicates attendeeAccessCode is encrypted and must be decrypted before comparisons',
    })),
    useCount: typebox_1.Type.Integer({ default: 0 }),
    useCountLimit: typebox_1.Type.Integer({ default: 5 }),
    attendeeTags: typebox_1.Type.Array(typebox_1.Type.String()),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the JSON Schema and the valid shapes for `Guest` records.
 */
exports.GuestModel = typebox_1.Type.Union([
    // V71 Guest w/ old Timestamp Columns
    typebox_1.Type.Intersect([
        typebox_1.Type.Omit(BaseGuestModel, ['createdAt', 'updatedAt']),
        timestamp_json_1.OldTimestampsJson,
    ]),
    // V71 Guest
    BaseGuestModel,
], { $id: 'GuestModel' });
