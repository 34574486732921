"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectionJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const connection_model_1 = require("./connection-model");
function connectionFromJson(item, mode) {
    const isConnectionItem = (0, json_schema_1.createValidator)(connection_model_1.ConnectionJson);
    if (isConnectionItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            lastHeartBeatAt: item.lastHeartBeatAt,
            showId: item.showId,
            status: item.status,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isConnectionItem.errors ?? [];
    }
}
exports.ConnectionJsonMapper = {
    fromJSON: connectionFromJson,
    /**
     * Create a JSON representation of a `Connection`.
     * @param record to convert to JSON
     * @returns a JSON represenation of `Connection`.
     */
    toJSON(record) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            lastHeartBeatAt: record.lastHeartBeatAt,
            showId: record.showId,
            status: record.status,
        };
    },
};
