"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetManagementMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_delete_message_1 = require("./asset-delete-message");
const asset_finalize_message_1 = require("./asset-finalize-message");
const create_mux_asset_message_1 = require("./create-mux-asset-message");
const generate_asset_thumbnail_message_1 = require("./generate-asset-thumbnail-message");
const transcode_asset_message_1 = require("./transcode-asset-message");
const watermark_asset_message_1 = require("./watermark-asset-message");
exports.AssetManagementMessage = typebox_1.Type.Union([
    asset_delete_message_1.AssetDeleteMessage,
    asset_finalize_message_1.AssetFinalizeMessage,
    create_mux_asset_message_1.CreateMuxAssetMessage,
    generate_asset_thumbnail_message_1.GenerateAssetThumbnailMessage,
    transcode_asset_message_1.TranscodeAssetMessage,
    watermark_asset_message_1.WatermarkAssetMessage,
]);
