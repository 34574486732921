"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentModelJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const content_model_model_1 = require("./content-model-model");
function fromJSON(item, mode) {
    const isContentModelItem = (0, json_schema_1.createValidator)(content_model_model_1.ContentModelJson);
    if (isContentModelItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            name: item.name,
            slug: item.slug,
            description: item.description,
            adminUI: item.adminUI,
            siteId: item.siteId,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isContentModelItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `ContentModel`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `ContentModel`.
 */
function toJSON(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        name: record.name,
        slug: record.slug,
        description: record.description,
        adminUI: record.adminUI,
        siteId: record.siteId,
    };
}
exports.ContentModelJsonMapper = {
    fromJSON,
    toJSON,
};
