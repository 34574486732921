"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const component_model_1 = require("./component-model");
function fromJson(item, mode) {
    const isComponentItem = (0, json_schema_1.createValidator)(component_model_1.Component);
    if (isComponentItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            defaultFieldData: item.defaultFieldData,
            description: item.description,
            name: item.name,
            reactName: item.reactName,
            slotConfiguration: item.slotConfiguration,
            slug: item.slug,
            version: item.version,
            isRestricted: item.isRestricted,
            tags: item.tags,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isComponentItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Component`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Component`.
 */
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        defaultFieldData: record.defaultFieldData,
        description: record.description,
        name: record.name,
        reactName: record.reactName,
        slotConfiguration: record.slotConfiguration,
        slug: record.slug,
        version: record.version,
        isRestricted: record.isRestricted,
        tags: record.tags,
    };
}
exports.ComponentJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
