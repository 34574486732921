"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const stream_model_1 = require("./stream-model");
const isStreamItem = (0, json_schema_1.createValidator)(stream_model_1.Stream);
function fromJson(item, mode) {
    if (isStreamItem(item)) {
        return {
            active: item.active,
            assetId: item.assetId,
            createdAt: item.createdAt,
            id: item.id,
            metadata: item.metadata,
            status: item.status,
            transcodeId: item.transcodeId,
            type: item.type,
            updatedAt: item.updatedAt,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isStreamItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Stream`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Stream`.
 */
function toJson(record) {
    return record;
}
exports.StreamJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
