"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestPushMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const show_instruction_model_1 = require("../model/show-instruction/show-instruction-model");
const BasePushMessage = typebox_1.Type.Object({
    /** Show for which guests will be notified */
    showId: typebox_1.Type.String({ format: 'uuid' }),
    /** "Live-ness" of the show to notify */
    target: typebox_1.Type.Union([typebox_1.Type.Literal('draft'), typebox_1.Type.Literal('published')]),
});
/**
 * List message schemas which can be pushed to guests. Each schema must include
 * a `notificationType`.
 */
exports.GuestPushMessage = typebox_1.Type.Union([
    typebox_1.Type.Intersect([
        BasePushMessage,
        typebox_1.Type.Object({
            notificationType: typebox_1.Type.Literal('new-instruction'),
        }),
        show_instruction_model_1.ShowInstructionJson,
    ]),
    typebox_1.Type.Intersect([
        BasePushMessage,
        typebox_1.Type.Object({
            notificationType: typebox_1.Type.Literal('heartbeat'),
            currentTime: typebox_1.Type.String({ format: 'date-time' }),
        }),
    ]),
]);
