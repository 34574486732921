"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuerySiteDetailsByDomain = exports.QuerySiteDetailsById = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
/**
 * Query for `Site` details necessary to pre-render site content for an
 * attendee. This only retrieves the page ids, a subsequent query will be
 * required to retrieve page details.
 * @param $siteId (Required) UUID of the site to be retrieved.
 */
exports.QuerySiteDetailsById = (0, graphql_tag_1.gql) `
  query SiteDetailsById($siteId: UUID!, $showType: ShowType) {
    site: siteById(id: $siteId) {
      ...SiteDetails
      pages {
        id
      }
    }
  }
  ${fragments_1.FragmentSiteDetails}
`;
/**
 * Query for `Site` details necessary to render site content for an
 * attendee. This retrieves full page details.
 * @param $domainName (Required) domain name of the site to be retrieved
 */
exports.QuerySiteDetailsByDomain = (0, graphql_tag_1.gql) `
  query SiteDetailsByDomain(
    $domainName: String!
    $showType: ShowType
    $versionId: String
  ) {
    site: siteByDomain(name: $domainName, versionId: $versionId) {
      ...SiteDetails
      pages {
        ...PageFields
      }
    }
  }
  ${fragments_1.FragmentPage}
  ${fragments_1.FragmentSiteDetails}
`;
