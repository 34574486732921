import {
  isLivePreviewDomain,
  isPreviewDomain,
} from '@backstage/utils/domain-helpers';
import Cookie from 'js-cookie';
import {useEffect, useMemo} from 'react';
import {config} from '../../config';

/**
 * Parses `Location` to find domain name.
 * @returns the value to use as the domain of the site.
 */
export function useDomainName(): LocationDetails | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const result = useMemo<LocationDetails | undefined>(() => {
    if (!location) {
      return undefined;
    } else if (isLivePreviewDomain(config, location)) {
      const [, pathShowId] = location.pathname.split('/');
      const queryParams = new URLSearchParams(location.search);
      const domainName =
        queryParams.get('host') ?? Cookie.get('host') ?? location.hostname;
      const showId = pathShowId === '' ? undefined : pathShowId;
      return {isPreview: false, domainName, showId};
    } else if (isPreviewDomain(config, location)) {
      const [, domainName, pathShowId] = location.pathname.split('/');
      const showId = pathShowId === '' ? undefined : pathShowId;
      return {isPreview: true, domainName, showId};
    } else {
      const [, pathShowId] = location.pathname.split('/');
      const showId = pathShowId === '' ? undefined : pathShowId;
      return {isPreview: false, domainName: location.hostname, showId};
    }
  }, [location]);
  useEffect(() => {
    if (result?.domainName && isLivePreviewDomain(config, location)) {
      Cookie.set('host', result?.domainName, {
        sameSite: 'strict',
        secure: true,
      });
    }
  }, [location, result?.domainName]);
  return result;
}

export interface LocationDetails {
  isPreview: boolean;
  domainName: string;
  showId?: string;
}
