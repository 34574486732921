"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowInstructionJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of show data records.
 */
exports.ShowInstructionJson = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    showId: typebox_1.Type.String({ format: 'uuid' }),
    kind: typebox_1.Type.String(),
    meta: (0, model_helpers_1.JsonObject)(),
    ...timestamp_json_1.TimestampsJson.properties,
});
