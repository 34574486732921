"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const guest_model_1 = require("./guest-model");
function fromJson(item, mode) {
    const isGuestItem = (0, json_schema_1.createValidator)(guest_model_1.GuestModel);
    if (isGuestItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            attendeeAccessCode: item.attendeeAccessCode,
            attendeeAccessCodeIV: item.attendeeAccessCodeIV,
            attendeeId: item.attendeeId,
            attendeeTags: item.attendeeTags,
            avatar: item.avatar,
            id: item.id,
            siteId: item.siteId,
            useCount: item.useCount,
            useCountLimit: item.useCountLimit,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isGuestItem.errors ?? [];
    }
}
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        attendeeAccessCode: record.attendeeAccessCode,
        attendeeAccessCodeIV: record.attendeeAccessCodeIV,
        attendeeId: record.attendeeId,
        attendeeTags: record.attendeeTags,
        avatar: record.avatar,
        id: record.id,
        siteId: record.siteId,
        useCount: record.useCount,
        useCountLimit: record.useCountLimit,
    };
}
exports.GuestJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
