"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateLimitRequestJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const rate_limit_request_model_1 = require("./rate-limit-request-model");
const isRateLimitRequest = (0, json_schema_1.createValidator)(rate_limit_request_model_1.RateLimitRequestJson);
function fromJSON(item, mode) {
    if (isRateLimitRequest(item)) {
        return {
            CacheKey: item.CacheKey,
            ExpiresAt: item.ExpiresAt,
            RequestId: item.RequestId,
            SortKey: item.SortKey,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isRateLimitRequest.errors ?? [];
    }
}
exports.RateLimitRequestJsonMapper = {
    fromJSON,
    toJSON: (record) => {
        return {
            CacheKey: record.CacheKey,
            ExpiresAt: record.ExpiresAt,
            RequestId: record.RequestId,
            SortKey: record.SortKey,
        };
    },
};
