"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteAnalyticsFilterSchema = void 0;
const typebox_1 = require("@sinclair/typebox");
/** Shape of filters for the analytics dashboard */
exports.SiteAnalyticsFilterSchema = typebox_1.Type.Object({
    dateRange: typebox_1.Type.Object({
        startDate: typebox_1.Type.String({ format: 'date-time' }),
        endDate: typebox_1.Type.String({ format: 'date-time' }),
    }),
    domains: typebox_1.Type.Array(typebox_1.Type.String({ format: 'hostname' }), {
        title: 'Domains',
        uniqueItems: true,
    }),
    shows: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }), {
        title: 'Shows',
        uniqueItems: true,
    }),
});
