"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsMediaConvertStreamName = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.AwsMediaConvertStreamName = typebox_1.Type.String({
    title: 'StreamName',
    description: 'StreamName describes the track included in the input of a MediaConvert Job.',
    pattern: '^[A-Za-z0-9_ ]*$',
    examples: ['Director commentary', 'track_2'],
});
