"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WatermarkJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const watermark_model_1 = require("./watermark-model");
const isWatermarkItem = (0, json_schema_1.createValidator)(watermark_model_1.Watermark);
function fromJson(item, mode) {
    if (isWatermarkItem(item)) {
        return {
            assetId: item.assetId,
            configurationId: item.configurationId,
            createdAt: item.createdAt,
            id: item.id,
            logUri: item.logUri,
            message: item.message,
            status: item.status,
            streamId: item.streamId,
            strength: item.strength,
            updatedAt: item.updatedAt,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isWatermarkItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Watermark`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Watermark`.
 */
function toJson(record) {
    return record;
}
exports.WatermarkJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
