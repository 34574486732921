import {VFC, ReactElement} from 'react';

export const LCDNotFoundLogo: VFC = (): ReactElement<SVGElement> => (
  <svg
    width="273"
    height="244"
    viewBox="0 0 273 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.69231 79.1246C4.48662 79.1246 3.5 80.114 3.5 81.3082V238.782C3.5 239.976 4.48662 240.965 5.69231 240.965H267.231C268.445 240.965 269.423 239.968 269.423 238.812V81.3082C269.423 80.114 268.436 79.1246 267.231 79.1246H5.69231ZM0.5 81.3082C0.5 78.4504 2.83646 76.1246 5.69231 76.1246H267.231C270.087 76.1246 272.423 78.4504 272.423 81.3082V238.812C272.423 241.648 270.078 243.965 267.231 243.965H5.69231C2.83646 243.965 0.5 241.639 0.5 238.782V81.3082Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M221.515 85.8246H266.054V235.923H221.515V85.8246ZM224.515 88.8246V232.923H263.054V88.8246H224.515Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5 85.8246H218.762V235.923H28.5V85.8246ZM31.5 88.8246V232.923H215.762V88.8246H31.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.60742 85.8246H23.7151V235.923H7.60742V85.8246ZM10.6074 88.8246V232.923H20.7151V88.8246H10.6074Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.953 234.816L29.6911 87.7178L30.3089 86.9314L217.57 234.029L216.953 234.816Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.57 87.7178L30.3089 234.816L29.6911 234.029L216.953 86.9314L217.57 87.7178Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 149.446H260.531V156.37H227.039V149.446ZM228.039 150.446V155.37H259.531V150.446H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7153 149.446H19.5769V156.37H11.7153V149.446ZM12.7153 150.446V155.37H18.5769V150.446H12.7153Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7153 140.574H19.5769V147.499H11.7153V140.574ZM12.7153 141.574V146.499H18.5769V141.574H12.7153Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7153 91.1529H19.5769V138.658H11.7153V91.1529ZM12.7153 92.1529V137.658H18.5769V92.1529H12.7153Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 159.084H260.531V166.009H227.039V159.084ZM228.039 160.084V165.009H259.531V160.084H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 168.723H260.531V175.648H227.039V168.723ZM228.039 169.723V174.648H259.531V169.723H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 178.362H260.531V185.286H227.039V178.362ZM228.039 179.362V184.286H259.531V179.362H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 188.031H260.531V194.956H227.039V188.031ZM228.039 189.031V193.956H259.531V189.031H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 197.67H260.531V204.594H227.039V197.67ZM228.039 198.67V203.594H259.531V198.67H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 207.309H260.531V214.233H227.039V207.309ZM228.039 208.309V213.233H259.531V208.309H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.039 216.947H260.531V223.872H227.039V216.947ZM228.039 217.947V222.872H259.531V217.947H228.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.461 97.5C234.349 97.5 226.961 104.887 226.961 114C226.961 123.113 234.349 130.5 243.461 130.5C252.574 130.5 259.961 123.113 259.961 114C259.961 104.887 252.574 97.5 243.461 97.5ZM225.961 114C225.961 104.335 233.796 96.5 243.461 96.5C253.126 96.5 260.961 104.335 260.961 114C260.961 123.665 253.126 131.5 243.461 131.5C233.796 131.5 225.961 123.665 225.961 114Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.461 100.5C236.006 100.5 229.961 106.544 229.961 114C229.961 121.456 236.006 127.5 243.461 127.5C250.917 127.5 256.961 121.456 256.961 114C256.961 106.544 250.917 100.5 243.461 100.5ZM228.961 114C228.961 105.992 235.453 99.5 243.461 99.5C251.47 99.5 257.961 105.992 257.961 114C257.961 122.008 251.47 128.5 243.461 128.5C235.453 128.5 228.961 122.008 228.961 114Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.961 61V78H126.961V61L127.961 61Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.766 68.3963L127.766 78.3963L127.157 77.6037L140.157 67.6037L140.766 68.3963Z"
      fill="white"
    />
    <path
      d="M177.446 223.311C161.416 224.968 143.508 226.073 124.031 226.104C103.846 226.135 85.3232 225.03 68.8309 223.311C66.7386 223.311 55.6002 223.126 46.5848 214.777C40.8925 209.497 37.354 202.222 37.354 194.179V127.353C37.354 111.268 51.4463 98.1912 68.8309 98.1912C85.0463 96.6564 102.954 95.6434 122.369 95.6127C142.369 95.582 160.831 96.595 177.477 98.1912C179.569 98.1912 190.708 98.3754 199.723 106.725C205.416 112.005 208.954 119.28 208.954 127.322V194.179C208.923 210.264 194.831 223.311 177.446 223.311Z"
      fill="white"
    />
    <path
      d="M126.971 55.4884C128.108 52.544 128.648 49.3531 127.111 46.4764C126.281 44.9243 124.909 43.6802 124.017 42.1706C123.391 41.1114 123.366 40.1935 123.366 38.9864C123.366 37.2052 123.002 36.0316 122.374 34.3902C121.668 32.5428 121.399 31.0529 121.603 29.0331C122.131 23.8063 127.271 21.0624 132.097 21.0624C134.268 21.0624 135.327 21.5147 135.943 23.7059C136.584 25.9911 136.725 28.9124 134.08 29.7941C129.614 31.2828 124.639 28.7441 120.943 26.4696C119.077 25.3216 118.118 23.6382 118.339 21.4229C118.58 19.0103 120.713 17.0433 122.144 15.2547C123.589 13.4484 125.085 11.0951 125.328 8.72594C125.533 6.73192 124.316 4.79743 122.995 3.39883C122.094 2.44448 120.787 1.66276 119.981 0.655164C119.823 0.45741 121.026 0.622688 121.063 0.635138C122.509 1.12332 123.935 1.87221 125.368 2.42753C130.455 4.39869 135.67 6.46674 140.188 9.56707C142.466 11.1296 146.491 13.9026 145.606 17.2473C145.152 18.9607 142.047 19.6448 140.669 20.2013C137.909 21.3156 135.22 22.4522 132.298 23.0451C129.525 23.6076 126.705 23.9038 123.907 24.3067C121.889 24.5973 120.225 25.1849 118.76 26.6499C117.416 27.9931 117.124 29.7666 116.697 31.5364C116.283 33.25 115.95 35.8203 116.376 37.6045C116.67 38.8303 118.721 39.4548 119.691 39.9076C122.549 41.2413 126.063 42.2987 129.184 42.6713C130.225 42.7956 133.061 43.4946 133.099 41.75C133.145 39.6314 132.068 38.5365 130.656 37.0037C128.467 34.6294 125.459 32.9773 123.446 30.475C122.648 29.4833 124.97 29.8942 125.529 29.8942"
      stroke="white"
      strokeLinecap="round"
    />
    <path
      d="M127.511 160.114L123.429 160.689L123.381 187.482L127.446 186.923L127.511 160.114Z"
      fill="black"
    />
    <path
      d="M135.983 172.895C135.869 172.895 135.756 172.815 135.723 172.704L132.662 159.411L128.807 159.938L128.758 186.731L132.306 186.236L132.338 171.473C132.338 171.345 132.435 171.234 132.565 171.218C132.581 171.218 132.581 171.218 132.597 171.218C132.711 171.218 132.824 171.298 132.84 171.409L136.307 185.677L139.741 185.197L139.789 158.404L136.242 158.899L136.209 172.624C136.209 172.751 136.112 172.863 135.983 172.879C135.999 172.895 135.983 172.895 135.983 172.895Z"
      fill="black"
    />
    <path
      d="M72.1629 157.797C72.1143 157.749 72.0819 157.685 72.0819 157.605L72.1305 135.509L68.0486 136.068L68 162.862L76.9412 161.616L76.9574 157.206L72.3896 157.845C72.3734 157.845 72.3734 157.845 72.3572 157.845C72.2762 157.861 72.2115 157.829 72.1629 157.797Z"
      fill="black"
    />
    <path
      d="M158.676 169.604C158.546 169.604 158.433 169.508 158.417 169.38L156.716 156.071L151.533 156.79L151.484 183.584L154.967 183.104L154.999 167.175C154.999 167.048 155.096 166.936 155.242 166.92H155.258C155.388 166.92 155.501 167.016 155.517 167.144L157.753 182.721L159.032 182.545L161.316 166.265C161.332 166.137 161.446 166.041 161.575 166.041H161.592C161.721 166.057 161.835 166.169 161.835 166.297L161.802 182.162L165.431 181.667L165.479 154.873L160.701 155.544L158.935 169.38C158.919 169.508 158.806 169.604 158.676 169.604Z"
      fill="black"
    />
    <path
      d="M145.264 179.957C145.215 179.909 145.183 179.845 145.183 179.765L145.199 173.039C145.199 172.911 145.296 172.799 145.426 172.783L149.135 172.272L149.152 167.863L145.507 168.374C145.491 168.374 145.491 168.374 145.475 168.374C145.41 168.374 145.345 168.358 145.313 168.31C145.264 168.262 145.232 168.198 145.232 168.118L145.248 162.175C145.248 162.047 145.345 161.935 145.475 161.919L150.253 161.264L150.269 156.966L141.198 158.228L141.15 185.022L150.464 183.728L150.48 179.318L145.539 180.005C145.523 180.005 145.523 180.005 145.507 180.005C145.377 180.021 145.313 180.005 145.264 179.957Z"
      fill="black"
    />
    <path
      d="M78.0751 161.472L82.1407 160.913L82.1893 134.103L78.1237 134.662L78.0751 161.472Z"
      fill="black"
    />
    <path
      d="M116.788 184.542C116.707 184.558 116.626 184.558 116.545 184.558C116.221 184.558 115.962 184.463 115.752 184.255C115.46 183.967 115.314 183.488 115.314 182.801L115.347 167.383C115.347 166.488 115.509 165.562 116.707 165.402C116.804 165.386 116.885 165.386 116.983 165.386C117.307 165.386 117.582 165.482 117.776 165.674C118.052 165.945 118.181 166.393 118.181 167.064V171.17L122.214 170.611L122.231 166.249C122.231 164.252 121.842 162.878 121.016 162.063C120.384 161.44 119.509 161.136 118.327 161.136C117.987 161.136 117.614 161.168 117.226 161.216L116.302 161.344C111.783 161.967 111.103 165.817 111.103 168.054L111.07 183.472C111.07 185.677 111.491 187.179 112.382 188.057C113.03 188.696 113.921 189.016 115.104 189.016C115.444 189.016 115.8 188.984 116.189 188.936L117.112 188.808C118.91 188.553 120.255 187.754 121.113 186.412C121.842 185.277 122.214 183.792 122.214 182.002L122.231 176.123L118.197 176.682L118.181 182.481C118.149 184.031 117.404 184.463 116.788 184.542Z"
      fill="black"
    />
    <path
      d="M121.437 151.055C121.372 151.055 121.307 151.039 121.275 150.991C121.226 150.943 121.194 150.879 121.194 150.799L121.21 144.089C121.21 143.961 121.307 143.849 121.437 143.833L125.146 143.322L125.162 138.912L121.518 139.424C121.502 139.424 121.502 139.424 121.486 139.424C121.421 139.424 121.356 139.408 121.324 139.36C121.275 139.312 121.243 139.248 121.243 139.168L121.259 133.224C121.259 133.097 121.356 132.985 121.486 132.969L126.264 132.314V128.016L117.193 129.278L117.144 156.072L126.458 154.777L126.474 150.368L121.534 151.055C121.469 151.055 121.453 151.055 121.437 151.055Z"
      fill="black"
    />
    <path
      d="M111.265 152.461C111.2 152.461 111.135 152.445 111.103 152.397C111.054 152.349 111.022 152.285 111.022 152.205L111.07 130.109L107.005 130.668L106.956 157.462L115.897 156.215L115.913 151.806L111.346 152.445C111.297 152.461 111.281 152.461 111.265 152.461Z"
      fill="black"
    />
    <path
      d="M98.3386 136.244C98.3872 136.292 98.4196 136.356 98.4196 136.436L98.371 158.644L102.437 158.085L102.485 135.797C102.485 135.669 102.582 135.557 102.712 135.541L105.773 135.11V130.812L95.1638 132.314L95.1477 136.612L98.1281 136.196C98.1443 136.196 98.1443 136.196 98.1605 136.196C98.2253 136.18 98.29 136.212 98.3386 136.244Z"
      fill="black"
    />
    <path
      d="M86.5467 137.89C86.5953 137.938 86.6277 138.002 86.6277 138.081L86.5791 160.289L90.6448 159.73L90.6934 137.442C90.6934 137.315 90.7905 137.203 90.9201 137.187L93.9815 136.771V132.474L83.3557 133.943V138.241L86.3361 137.826C86.3523 137.826 86.3523 137.826 86.3685 137.826C86.4333 137.826 86.4981 137.842 86.5467 137.89Z"
      fill="black"
    />
    <path
      d="M169.642 154.282L166.418 181.523L169.95 181.027L170.355 176.586C170.371 176.474 170.452 176.378 170.581 176.362L173.335 175.979C173.351 175.979 173.351 175.979 173.367 175.979C173.432 175.979 173.481 175.995 173.529 176.027C173.578 176.075 173.627 176.139 173.627 176.202L174.08 180.468L178.016 179.925L174.647 153.595L169.642 154.282ZM172.962 171.841L171.1 172.096C171.083 172.096 171.083 172.096 171.067 172.096C171.002 172.096 170.938 172.064 170.889 172.032C170.824 171.985 170.808 171.905 170.808 171.825L171.715 162.334C171.731 162.207 171.845 162.111 171.974 162.111C172.104 162.111 172.217 162.207 172.234 162.334L173.189 171.553C173.189 171.697 173.092 171.825 172.962 171.841Z"
      fill="black"
    />
    <path
      d="M114 193.415L116.138 193.112C117.359 192.922 117.855 193.339 117.855 194.592L117.894 198.655C117.894 199.87 117.436 200.515 116.214 200.667L114.076 200.971L114 193.415ZM115.451 199.604L115.947 199.528C116.443 199.452 116.596 199.186 116.596 198.655L116.558 194.896C116.558 194.364 116.367 194.137 115.909 194.213L115.412 194.288L115.451 199.604Z"
      fill="black"
    />
    <path
      d="M126.788 199.147L125.414 199.336L125.338 191.705L126.712 191.515L126.788 199.147Z"
      fill="black"
    />
    <path
      d="M135.72 198.009C134.804 198.123 134.232 197.44 134.232 196.415L134.193 192.314C134.193 191.137 134.69 190.302 135.988 190.074L136.217 190.036C137.629 189.847 138.011 190.53 138.011 191.669V192.162L136.713 192.352V191.935C136.713 191.403 136.522 191.175 136.14 191.213C135.758 191.251 135.568 191.555 135.568 192.087L135.606 195.959C135.606 196.491 135.797 196.794 136.255 196.718C136.713 196.643 136.904 196.301 136.904 195.769V194.327L136.178 194.44V193.264L138.163 192.96L138.201 197.516L137.362 197.63C137.324 197.554 137.209 197.174 137.094 196.908C136.98 197.212 136.598 197.857 135.835 197.933L135.72 198.009Z"
      fill="black"
    />
    <path
      d="M147.024 196.339L145.65 196.529L145.573 188.746L146.947 188.556L147.024 196.339Z"
      fill="black"
    />
    <path
      d="M155.534 188.521L154.313 188.711V187.458L158.13 186.888V188.141L156.909 188.331L156.985 194.975L155.611 195.165L155.534 188.521Z"
      fill="black"
    />
    <path
      d="M167.521 192.009L166.147 192.199L165.956 193.68L164.772 193.832L165.956 185.707L167.635 185.441L169.086 193.224L167.788 193.414L167.521 192.009ZM166.299 190.984L167.33 190.832L167.025 189.086C166.872 188.213 166.796 187.643 166.757 187.226C166.719 187.681 166.643 188.289 166.528 189.162L166.299 190.984Z"
      fill="black"
    />
    <path
      d="M177.473 184L177.549 190.606L179 190.416V191.859L176.213 192.239L176.137 184.19L177.473 184Z"
      fill="black"
    />
  </svg>
);
