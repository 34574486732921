"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of show data records.
 */
exports.ShowJson = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    // isDefault is introduced as optional because some shows have already been
    // copied to DynamoDB without this value; if it isn't optional then shows from
    // DynamoDB won't validate
    isDefault: typebox_1.Type.Optional(typebox_1.Type.Boolean({ default: false })),
    // `type` is introduced as optional because some shows have already been
    // copied to DynamoDB without this value; if it isn't optional then shows from
    // DynamoDB won't validate
    type: typebox_1.Type.Optional(typebox_1.Type.Union([
        typebox_1.Type.Literal('draft'),
        typebox_1.Type.Literal('pre-production'),
        typebox_1.Type.Literal('production'),
    ], { default: 'pre-production' })),
    ...timestamp_json_1.TimestampsJson.properties,
});
