"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsMediaConvertJobProgress = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.AwsMediaConvertJobProgress = typebox_1.Type.Object({
    phaseProgress: typebox_1.Type.Object({
        PROBING: typebox_1.Type.Object({
            status: typebox_1.Type.String(),
            percentComplete: typebox_1.Type.Optional(typebox_1.Type.Number()),
        }),
        TRANSCODING: typebox_1.Type.Object({
            status: typebox_1.Type.String(),
            percentComplete: typebox_1.Type.Optional(typebox_1.Type.Number()),
        }),
        UPLOADING: typebox_1.Type.Object({
            status: typebox_1.Type.String(),
            percentComplete: typebox_1.Type.Optional(typebox_1.Type.Number()),
        }),
    }),
    jobPercentComplete: typebox_1.Type.Number(),
    currentPhase: typebox_1.Type.String(),
    retryCount: typebox_1.Type.Number(),
});
