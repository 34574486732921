"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestExternalJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const guest_external_model_1 = require("./guest-external-model");
function fromJson(item, mode) {
    const isGuestExternalItem = (0, json_schema_1.createValidator)(guest_external_model_1.GuestExternalModel);
    if (isGuestExternalItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            attendeeId: item.attendeeId,
            avatar: item.avatar,
            email: item.email,
            externalId: item.externalId,
            name: item.name,
            oauth: item.oauth,
            serviceType: item.serviceType,
            siteId: item.siteId,
            tags: item.tags,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isGuestExternalItem.errors ?? [];
    }
}
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        attendeeId: record.attendeeId,
        avatar: record.avatar,
        email: record.email,
        externalId: record.externalId,
        name: record.name,
        oauth: record.oauth,
        serviceType: record.serviceType,
        siteId: record.siteId,
        tags: record.tags,
    };
}
exports.GuestExternalJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
