export function isContent(definition) {
    return !isLayout(definition);
}
export function isLayout(definition) {
    return (definition.component === 'AddEvent' ||
        definition.component === 'BackgroundVideo' ||
        definition.component === 'ButtonOverlay' ||
        definition.component === 'Stacked' ||
        definition.component === 'Style' ||
        definition.component === 'Carousel' ||
        definition.component === 'Container' ||
        definition.component === 'CollectionList' ||
        definition.component === 'Modal' ||
        definition.component === 'Drawer' ||
        definition.component === 'PeacockBackground' ||
        definition.component === 'RtcRoomContainer' ||
        definition.component === 'Video');
}
