"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendeeJson = exports.AttendeeModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of attendee data records.
 */
exports.AttendeeModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    email: (0, model_helpers_1.Nullable)(typebox_1.Type.String({ format: 'email' })),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.AttendeeJson = typebox_1.Type.Union([
    // V34 Timestamp Columns
    exports.AttendeeModel,
    // Base
    typebox_1.Type.Pick(exports.AttendeeModel, ['email', 'id', 'name']),
]);
