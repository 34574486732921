"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetFinalizeMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * Informs the asset management service an asset has been indicated as
 * finalized. This is a command/notification sent to the asset management
 * service.
 */
exports.AssetFinalizeMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        assetId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('Asset Finalize'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
