"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRegex = void 0;
function isRegex(value) {
    // See https://github.com/ajv-validator/ajv-formats/blob/4ca86d21bd07571a30178cbb3714133db6eada9a/src/formats.ts#L260C1-L269C2
    const Z_ANCHOR = /[^\\]\\Z/;
    if (Z_ANCHOR.test(value))
        return false;
    try {
        new RegExp(value);
        return true;
    }
    catch {
        return false;
    }
}
exports.isRegex = isRegex;
