import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import type {Operation} from '@apollo/client';
import {possibleTypes} from '@backstage/attendee-ui-types';
import {readAccessToken} from '@backstage-components/attendee-container';
import {useEffect, useMemo, useRef, useState} from 'react';
import {config} from './config';

const httpLink = new HttpLink({
  uri: `${config.endpointBase}/attendee`,
  headers: {
    ...(config.endpointBase.includes('localhost') || config.stage === 'local'
      ? {'X-Accept-Encoding': 'identity'}
      : {}),
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const {environmentId} = operation.getContext();
  const accessToken = readAccessToken(environmentId);
  if (accessToken !== null) {
    applyAccessToken(operation, accessToken);
  }
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({possibleTypes}),
  link: ApolloLink.from([authMiddleware, httpLink]),
});

function applyAccessToken(operation: Operation, token: string): void {
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}

interface OnGuestAuthChangeHanders {
  /**
   * Reads the current session token and check if the session token in memory
   * within the `useOnGuestAuthChange` hook and the current session token are
   * the same.
   */
  hasSessionTokenChanged: () => boolean;
  /**
   * Reads the current session token and sets it in memory. This function is
   * intended to be called just before or just after making a GraphQL call to
   * ensure the `useOnGuestAuthChange` hook has access to the most recently used
   * session token.
   */
  updateSessionToken: () => void;
}

/**
 * Function which evicts query response data from the cache if the session
 * token has changed. If the session token has not changed it is a noop.
 */
export function evictSiteByDomain(
  domainName: string,
  siteVersionId?: string | null
): void {
  client.cache.evict({
    id: 'ROOT_QUERY',
    args: {name: domainName, versionId: siteVersionId},
    fieldName: 'siteByDomain',
  });
}

/**
 * Evict `siteByDomain` query data for `domainName` and `siteVersionId` from
 * `ApolloClient` cache when `sessionKey` associated with the `showId` changes.
 */
export function useOnGuestAuthChange(
  showId: string | undefined
): OnGuestAuthChangeHanders {
  const sessionKey = useRef<string | typeof NoAuthHeader>(NoAuthHeader);
  const [authChangeDate, setAuthChangeDate] = useState(Date.now());
  useEffect(() => {
    const onGuestAuth = (): void => {
      setAuthChangeDate(Date.now());
    };
    document.body.addEventListener('GuestAuth:success', onGuestAuth);
    return () => {
      document.body.removeEventListener('GuestAuth:success', onGuestAuth);
    };
  }, []);
  // authChangeDate is used to update the memoized functions after a guest
  // authentication has been performed
  // biome-ignore lint/correctness/useExhaustiveDependencies: see above
  const {hasSessionTokenChanged, updateSessionToken} = useMemo(() => {
    const sessionToken = readAccessToken(showId);
    const nextSessionKey =
      typeof sessionToken === 'string' ? sessionToken : NoAuthHeader;
    return {
      hasSessionTokenChanged: () => nextSessionKey !== sessionKey.current,
      updateSessionToken: () => {
        sessionKey.current = nextSessionKey;
      },
    };
  }, [authChangeDate, showId]);
  return {hasSessionTokenChanged, updateSessionToken};
}

const NoAuthHeader = Symbol('NoAuthHeader');
