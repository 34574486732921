"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentFieldJson = exports.ContentFieldModelMinimal = exports.ContentFieldModel = exports.ContentFieldSettings = exports.ContentFieldKindDefs = exports.ContentFieldKind = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const model_helpers_1 = require("../model-helpers");
const site_variable_model_1 = require("../site-variable/site-variable-model");
const slug_1 = require("../../data/slug");
exports.ContentFieldKind = typebox_1.Type.Union(site_variable_model_1.variableKinds.map((s) => s.properties.kind), { $id: 'ContentFieldKind' });
exports.ContentFieldKindDefs = typebox_1.Type.Union(site_variable_model_1.variableKinds, {
    $id: 'ContentFieldKindDefs',
});
const Flags = typebox_1.Type.Array(typebox_1.Type.Union([typebox_1.Type.Literal('required')]), {
    uniqueItems: true,
});
const ContentFieldSettingsData = typebox_1.Type.Object({
    flags: Flags,
}, {
    $id: 'ContentFieldSettingsData',
    description: 'Represents the structure of settings for a content field.',
});
const ContentFieldSettingsV1 = typebox_1.Type.Object({
    _version: typebox_1.Type.Literal(1),
    data: ContentFieldSettingsData,
}, { $id: 'ContentFieldSettings' });
exports.ContentFieldSettings = ContentFieldSettingsV1;
/**
 * Defines the JSON serialized shape of the data records.
 */
exports.ContentFieldModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    slug: typebox_1.Type.String({ pattern: slug_1.slugPattern }),
    description: typebox_1.Type.String(),
    kind: exports.ContentFieldKind,
    contentModelId: typebox_1.Type.String({ format: 'uuid' }),
    adminUI: (0, model_helpers_1.JsonValue)(),
    settings: exports.ContentFieldSettings,
    sortOrder: typebox_1.Type.Integer(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ContentFieldModel' });
/**
 * Defines the minimal shape of the data records, useful for creating within
 * `ContentModel`.
 * **NOTE** If `ContentModel` needs any of these fields, they should moved from
 * `ContentFieldModel` to here.
 */
exports.ContentFieldModelMinimal = typebox_1.Type.Pick(exports.ContentFieldModel, ['id', 'name', 'slug', 'description', 'kind', 'settings'], { $id: 'ContentFieldModelMinimal' });
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * the data records.
 */
exports.ContentFieldJson = exports.ContentFieldModel;
