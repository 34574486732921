"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidSlug = exports.slugPattern = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../json-schema");
/**
 * Pattern to match against incoming values for the `slug` column of `site_variable` table.
 */
exports.slugPattern = '^[a-z][a-z0-9]*(-[a-z0-9]+)*$';
/**
 * Function to validate a given value matches the pattern for `slug` in a `SiteVariable`.
 */
exports.isValidSlug = (0, json_schema_1.createValidator)(typebox_1.Type.String({ pattern: exports.slugPattern }));
