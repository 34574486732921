"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimestampsJsonMapper = exports.OldTimestampsJson = exports.TimestampsJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../json-schema");
const DateTransform = typebox_1.Type.Transform(typebox_1.Type.String({ format: 'date-time' }))
    .Decode((value) => new Date(value))
    .Encode((value) => value.toISOString());
/**
 * Defines the `createdAt` and `updatedAt` model columns as timestamps in JSON
 * schema.
 */
exports.TimestampsJson = typebox_1.Type.Object({
    createdAt: DateTransform,
    updatedAt: DateTransform,
});
exports.OldTimestampsJson = typebox_1.Type.Object({
    createDate: DateTransform,
});
/**
 * Maps Timestamps to TimestampsJson and back again. For models which do not
 * require the more conditional handling of
 */
exports.TimestampsJsonMapper = {
    fromJSON: timestampsFromJson,
    toJSON: timestampsToJson,
};
const MaybeTimestampsJson = typebox_1.Type.Union([
    exports.OldTimestampsJson,
    typebox_1.Type.Partial(exports.TimestampsJson),
]);
const isTimestampsItem = (0, json_schema_1.createValidator)(MaybeTimestampsJson);
/**
 * Extract timestamp data out of the given object which may have new style
 * (`createdAt` and `updatedAt`) or old style (`createDate`) timestamps. If
 * `updatedAt` isn't provided the creation date will be used as the updated
 * date. If neither are provided the "current" time will be used.
 * @param item from which timestamps will be extracted
 * @returns timestamps extracted from `item`
 */
function timestampsFromJson(item) {
    if (!isTimestampsItem(item)) {
        return { createdAt: new Date(), updatedAt: new Date() };
    }
    else if ('createDate' in item) {
        return {
            createdAt: new Date(item.createDate),
            updatedAt: new Date(item.createDate),
        };
    }
    else {
        return {
            createdAt: typeof item.createdAt === 'string'
                ? new Date(item.createdAt)
                : new Date(),
            updatedAt: typeof item.updatedAt === 'string'
                ? new Date(item.updatedAt)
                : new Date(),
        };
    }
}
/**
 * Format the timestamps of `item` consistent with the format expected by
 * `TimestampsJson`
 * @param item whose timestamps will be formatted as strings
 * @returns JSON formatted timestamps
 */
function timestampsToJson(item) {
    return {
        createdAt: item.createdAt.toISOString(),
        updatedAt: item.updatedAt.toISOString(),
    };
}
