import type {Config, Stage} from '@backstage/utils/config';

const DEFAULT_ENDPOINT = 'https://api.lcdbackstage.com';
const DEFAULT_LEGACY_ENDPOINT = 'https://api.lcdigital.io/graphql';

const NODE_ENV = process.env.NODE_ENV;

const computedStage = getStage();

/**
 * The configuration values read from environment variables at build time.
 */
export const config: Config = {
  bitmovinKey: getRequiredKey(computedStage, 'BITMOVIN_KEY'),
  bitmovinAnalyticsKey: getRequiredKey(computedStage, 'BITMOVIN_ANALYTICS_KEY'),
  endpointBase: getStagedKey(computedStage, 'ENDPOINT', DEFAULT_ENDPOINT),
  legacyEndpoint: getStagedKey(
    computedStage,
    'LEGACY_ENDPOINT',
    DEFAULT_LEGACY_ENDPOINT
  ),
  pusherCluster: getStagedKey(
    computedStage,
    'PUSHER_CONFIG_KEY',
    (v) => v?.split(':::')[1] || 'us2'
  ),
  pusherKey: getStagedKey(
    computedStage,
    'PUSHER_CONFIG_KEY',
    (v) => v?.split(':::')[0]
  ),
  stage: computedStage,
  streamKey: getRequiredKey(computedStage, 'STREAM_KEY'),
  livekitEndpoint: getStagedKey(
    computedStage,
    'LIVEKIT_ENDPOINT',
    DEFAULT_ENDPOINT
  ),
};

/**
 * Reads `key` from `process.env` during build.
 * @param key to read from environment variables.
 * @returns the value in the environment variables or `undefined` if no value
 * exists.
 * @private exported for tests
 */
export function getKey(key: string): string | undefined;

/**
 * Reads `key` from `process.env` during build, falling back to `defaultValue`
 * if `key` is not in `process.env`.
 * @param key to read from environment variables.
 * @param defaultValue to return if no value is present.
 * @returns the value in the environment variables or `defaultValue` if no value
 * exists in the environment.
 * @private exported for tests
 */
export function getKey(key: string, defaultValue: string): string;

export function getKey(key: string, defaultValue?: string): string | undefined {
  const value = process.env[key];
  if (typeof value === 'undefined' && typeof defaultValue !== 'undefined') {
    if (NODE_ENV !== 'test') {
      console.warn(`${key} not in environment, falling back to default.`);
    }
    return defaultValue;
  } else {
    return value;
  }
}

/**
 * Attempts to read `REACT_APP_${stage}_${key}` from `process.env` and then
 * falls back to `REACT_APP_${key}`.
 * @param stage to use when reading environment variables.
 * @param key to read from environment variables.
 * @returns the value in the environment variables.
 * @throws `Error` if no value exists.
 * @private exported for tests
 */
export function getRequiredKey(stage: string, key: string): string {
  const value = getStagedKey(stage, key);
  if (typeof value === 'undefined') {
    throw new Error(`REACT_APP_${key} is not set in environment.`);
  }
  return value;
}

/**
 * Attempts to read `REACT_APP_${stage}_${key}` from `process.env` and then
 * falls back to `REACT_APP_${key}` if it is not available.
 * @param stage to use when reading environment variables.
 * @param key to read from environment variables.
 * @returns the value in the environment variables or `undefined` if no value
 * exists.
 * @private exported for tests
 */
export function getStagedKey(stage: string, key: string): string | undefined;

/**
 * Attempts to read `REACT_APP_${stage}_${key}` from `process.env`, falls back
 * to `REACT_APP_${key}` and then falling back to `defaultValue` if neither are
 * available.
 * @param stage to use when reading environment variables.
 * @param key to read from environment variables.
 * @param defaultValue to return if no value is present.
 * @returns the value in the environment variables or `defaultValue` if no value
 * exists in the environment.
 * @private exported for tests
 */
export function getStagedKey(
  stage: string,
  key: string,
  defaultValue: string
): string;

/**
 * Attempts to read `REACT_APP_${stage}_${key}` from `process.env`, falls back
 * to `REACT_APP_${key}` and then calling `provider` to get the resulting value.
 * @param stage to use when reading environment variables.
 * @param key to read from environment variables.
 * @param provider to compute value based on the staged environment variables.
 * @returns the value from the `provider` function.
 * @private exported for tests
 */
export function getStagedKey<T extends string | undefined = string | undefined>(
  stage: string,
  key: string,
  provider: (value?: string) => T
): T;

export function getStagedKey(
  stage: string,
  key: string,
  provider?: string | ((value?: string) => string | undefined)
): string | undefined {
  const STAGE = stage.toUpperCase();
  const values = [
    getKey(`REACT_APP_${STAGE}_${key}`),
    getKey(`REACT_APP_${key}`),
  ];
  const value = values.find((v) => typeof v === 'string');
  if (typeof value === 'undefined' && typeof provider === 'string') {
    if (NODE_ENV !== 'test') {
      console.warn(`${key} not in environment, falling back to default.`);
    }
    return provider;
  } else if (typeof provider === 'function') {
    return provider(value);
  } else {
    return value;
  }
}

/**
 * Determine stage based on the environment into which the application is
 * loaded.
 * @returns the `Stage` based on globals.
 */
function getStage(): Stage {
  if (
    process.env.REACT_APP_STAGE === 'developer' ||
    process.env.REACT_APP_STAGE === 'production' ||
    process.env.REACT_APP_STAGE === 'local'
  ) {
    return process.env.REACT_APP_STAGE;
  } else if (window.location.host === 'lcdbackstage.com') {
    return 'production';
  } else {
    return 'developer';
  }
}
