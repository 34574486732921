"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetDeleteMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
const model_1 = require("../../model");
/**
 * Indicates an asset has been removed from the asset managment service. This is
 * a notification from the asset management service.
 */
exports.AssetDeleteMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Intersect([
        typebox_1.Type.Pick(model_1.Asset, ['id', 'fileName', 'uri']),
        typebox_1.Type.Object({
            /** Identifiers for mux transcodes associated with the asset */
            muxIds: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
        }),
    ]),
    'detail-type': typebox_1.Type.Literal('Asset Delete'),
    source: message_source_1.AssetManagmentSource,
    traceId: tracing_1.TraceId,
});
