"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteVariable = exports.variableKinds = void 0;
const typebox_1 = require("@sinclair/typebox");
const data_1 = require("../../data");
const video_source_1 = require("../video-source");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const SiteVariableBase = typebox_1.Type.Object({
    /** Identifier for the instance */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Name of the site variable, must be unique within the site */
    name: typebox_1.Type.String({
        title: 'Field Label',
        description: 'All label names must be unique.',
    }),
    /** Descriptive text for the site variable */
    description: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Description',
        description: 'Add a helpful descriptive text to your field to better help describe it.',
    })),
    /** Identifier for the site to which the variable belongs */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** Data to help with presentation in management interface */
    adminUI: (0, model_helpers_1.JsonObject)(),
    /** Template author specified ordering within a site for sorting */
    sortOrder: typebox_1.Type.Integer(),
    /** `slug` will be used to build up a `css` variable name for eligible values */
    slug: typebox_1.Type.String({ title: 'Variable Identifier', pattern: data_1.slugPattern }),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * A simple array of TypeBox objects that describe the intersection of Site Variable
 * kinds and Content Field kinds. Useful for creating TypeBox unions for those kinds.
 * Note: Explicit typing as a homogeneous array (TSchema[] or TObject[]) makes the
 * type too generic and causes TypeScript to lose the specific type information, so
 * this is being left without an explicit type.
 */
exports.variableKinds = [
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('color', { default: 'color' }),
        value: typebox_1.Type.String({
            title: 'Select Color',
            default: '#000000',
            $id: 'SiteVariableColorValue',
        }),
    }, { $id: 'SiteVariableColorModel' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('date', { default: 'date' }),
        value: typebox_1.Type.String({
            title: 'Select Date & Time',
            format: 'date-time',
            $id: 'SiteVariableDateValue',
        }),
    }, { $id: 'SiteVariableDateModel' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('image', { default: 'image' }),
        value: typebox_1.Type.Object({
            uri: typebox_1.Type.String({ format: 'uri', title: 'Image' }),
        }, { title: 'Select Image', $id: 'SiteVariableImageValue' }),
    }, { $id: 'SiteVariableImageModel' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('link', { default: 'link' }),
        value: typebox_1.Type.String({
            title: 'URL Link',
            format: 'uri',
            $id: 'SiteVariableLinkValue',
        }),
    }, { $id: 'SiteVariableLink' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('number', { default: 'number' }),
        value: typebox_1.Type.Number({
            title: 'Enter Number',
            $id: 'SiteVariableNumberValue',
        }),
    }, { $id: 'SiteVariableNumber' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('rich_text', { default: 'rich_text' }),
        value: typebox_1.Type.String({
            title: 'Enter Rich Text',
            $id: 'SiteVariableRichTextValue',
        }),
    }, { $id: 'SiteVariableRichText' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('switch', { default: 'switch' }),
        value: typebox_1.Type.Boolean({
            title: 'Set Switch',
            $id: 'SiteVariableSwitchValue',
        }),
    }, { $id: 'SiteVariableSwitchModel' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('text', { default: 'text' }),
        value: typebox_1.Type.String({
            title: 'Enter Plain Text',
            $id: 'SiteVariableTextValue',
        }),
    }, { $id: 'SiteVariableTextModel' }),
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('video', { default: 'video' }),
        value: typebox_1.Type.Union(video_source_1.VideoSource.anyOf, { $id: 'SiteVariableVideoValue' }),
    }, { $id: 'SiteVariableVideoModel' }),
];
/**
 * Value defines the JSON schema of site_variable data records.
 */
exports.SiteVariable = typebox_1.Type.Union([
    ...exports.variableKinds,
    typebox_1.Type.Object({
        ...SiteVariableBase.properties,
        kind: typebox_1.Type.Literal('show_variable', { default: 'show_variable' }),
        value: typebox_1.Type.Null(),
    }),
], { $id: 'SiteVariableModel' });
