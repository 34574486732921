"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryPageById = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
/**
 * Query for `Page` details necessary to pre-render page content for an
 * attendee.
 * @param $pageId (Required) id of the page to be retrieved
 */
exports.QueryPageById = (0, graphql_tag_1.gql) `
  query PageById($pageId: UUID!) {
    page: pageById(id: $pageId) {
      ...PageFields
    }
  }
  ${fragments_1.FragmentPage}
`;
