"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptionConfigJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const caption_config_model_1 = require("./caption-config-model");
const isCaptionConfigItem = (0, json_schema_1.createValidator)(caption_config_model_1.CaptionConfig);
function fromJson(item, mode) {
    if (isCaptionConfigItem(item)) {
        return {
            assetId: item.assetId,
            createdAt: item.createdAt,
            description: item.description,
            id: item.id,
            language: item.language,
            modifier: item.modifier,
            slug: item.slug,
            updatedAt: item.updatedAt,
            uri: item.uri,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isCaptionConfigItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `CaptionConfig`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `CaptionConfig`.
 */
function toJson(record) {
    return record;
}
exports.CaptionConfigJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
