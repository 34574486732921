"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestSessionJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const guest_session_model_1 = require("./guest-session-model");
function fromJson(item, mode) {
    const isGuestSessionItem = (0, json_schema_1.createValidator)(guest_session_model_1.GuestSession);
    if (isGuestSessionItem(item)) {
        if ('passcodeId' in item) {
            return {
                ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
                id: item.id,
                domainName: item.domainName,
                name: item.name,
                passcodeId: item.passcodeId,
                showId: item.showId,
            };
        }
        else if ('accessCodeId' in item) {
            return {
                ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
                id: item.id,
                accessCodeId: item.accessCodeId,
                attendeeId: item.attendeeId,
                domainName: item.domainName,
                showId: item.showId,
            };
        }
        else if ('externalId' in item) {
            return {
                ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
                id: item.id,
                domainName: item.domainName,
                email: item.email,
                externalId: item.externalId,
                name: item.name,
                serviceType: item.serviceType,
                showId: item.showId,
            };
        }
        else {
            return {
                ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
                id: item.id,
                agreementAnswer: item.agreementAnswer,
                agreementText: item.agreementText,
                domainName: item.domainName,
                email: item.email,
                name: item.name,
                showId: item.showId,
            };
        }
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isGuestSessionItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `GuestSession`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `GuestSession`.
 */
function toJson(record) {
    if ('passcodeId' in record) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            domainName: record.domainName,
            name: record.name,
            passcodeId: record.passcodeId,
            showId: record.showId,
        };
    }
    else if ('accessCodeId' in record) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            accessCodeId: record.accessCodeId,
            attendeeId: record.attendeeId,
            domainName: record.domainName,
            showId: record.showId,
        };
    }
    else if ('externalId' in record) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            domainName: record.domainName,
            email: record.email,
            externalId: record.externalId,
            name: record.name,
            serviceType: record.serviceType,
            showId: record.showId,
        };
    }
    else {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            agreementAnswer: record.agreementAnswer,
            agreementText: record.agreementText,
            domainName: record.domainName,
            email: record.email,
            name: record.name,
            showId: record.showId,
        };
    }
}
exports.GuestSessionJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
