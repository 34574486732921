"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateVerificationRecord = void 0;
const typebox_1 = require("@sinclair/typebox");
/** Shape of the record associated with certificate validation */
exports.CertificateVerificationRecord = typebox_1.Type.Object({
    /** Name of the DNS entry */
    name: typebox_1.Type.String(),
    /** Type of DNS record with `name` holding `value` */
    type: typebox_1.Type.String(),
    /** Value the DNS entry must hold */
    value: typebox_1.Type.String(),
});
