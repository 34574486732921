"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentFieldJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const content_field_model_1 = require("./content-field-model");
function fromJSON(item, mode) {
    const isContentFieldItem = (0, json_schema_1.createValidator)(content_field_model_1.ContentFieldJson);
    if (isContentFieldItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            contentModelId: item.contentModelId,
            adminUI: item.adminUI,
            name: item.name,
            slug: item.slug,
            description: item.description,
            kind: item.kind,
            settings: item.settings,
            sortOrder: item.sortOrder,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isContentFieldItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `ContentField`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `ContentField`.
 */
function toJSON(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        contentModelId: record.contentModelId,
        adminUI: record.adminUI,
        name: record.name,
        slug: record.slug,
        description: record.description,
        kind: record.kind,
        settings: record.settings,
        sortOrder: record.sortOrder,
    };
}
exports.ContentFieldJsonMapper = {
    fromJSON,
    toJSON,
};
