"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteSettings = exports.isSiteResourceData = exports.isSiteResource = exports.SiteJsonMapper = exports.SiteDynamoMapper = void 0;
var site_dynamodb_1 = require("./site-dynamodb");
Object.defineProperty(exports, "SiteDynamoMapper", { enumerable: true, get: function () { return site_dynamodb_1.SiteDynamoMapper; } });
var site_json_1 = require("./site-json");
Object.defineProperty(exports, "SiteJsonMapper", { enumerable: true, get: function () { return site_json_1.SiteJsonMapper; } });
var site_resource_1 = require("./site-resource");
Object.defineProperty(exports, "isSiteResource", { enumerable: true, get: function () { return site_resource_1.isSiteResource; } });
Object.defineProperty(exports, "isSiteResourceData", { enumerable: true, get: function () { return site_resource_1.isSiteResourceData; } });
var site_settings_1 = require("./site-settings");
Object.defineProperty(exports, "SiteSettings", { enumerable: true, get: function () { return site_settings_1.SiteSettings; } });
