"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeErrors = exports.ValidationError = void 0;
/**
 * Custom `Error` implementation indicating a configuration issue.
 */
class ValidationError extends Error {
    constructor(errors) {
        super(encodeErrors(errors));
        // Set the prototype explicitly
        // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
        Object.setPrototypeOf(this, ValidationError.prototype);
    }
}
exports.ValidationError = ValidationError;
function encodeErrors(errors) {
    return errors
        .map((e) => e.instancePath !== ''
        ? `"${e.instancePath.replace(/\//g, '.').slice(1)}": ${e.message}`
        : e.message)
        .filter((m) => typeof m === 'string')
        .join('\n');
}
exports.encodeErrors = encodeErrors;
