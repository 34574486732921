"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveSiteViewServiceMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const live_site_distribution_child_updated_1 = require("./live-site-distribution-child-updated");
const live_site_distribution_updated_1 = require("./live-site-distribution-updated");
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage-services/live-site-view`.
 */
exports.LiveSiteViewServiceMessage = typebox_1.Type.Union([live_site_distribution_child_updated_1.LiveSiteDistributionChildUpdatedMessage, live_site_distribution_updated_1.LiveSiteDistributionUpdatedMessage], { $id: 'LiveSiteViewServiceMessage' });
