"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsMediaConvertInputDetail = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.AwsMediaConvertInputDetail = typebox_1.Type.Object({
    id: typebox_1.Type.Number(),
    uri: typebox_1.Type.String(),
    audio: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Object({
        codec: typebox_1.Type.String(),
        channels: typebox_1.Type.Number(),
        language: typebox_1.Type.String(),
        streamId: typebox_1.Type.Number(),
        sampleRate: typebox_1.Type.Number(),
    }))),
    video: typebox_1.Type.Array(typebox_1.Type.Object({
        sar: typebox_1.Type.String(),
        codec: typebox_1.Type.String(),
        width: typebox_1.Type.Number(),
        fourCC: typebox_1.Type.String(),
        height: typebox_1.Type.Number(),
        bitDepth: typebox_1.Type.Number(),
        standard: typebox_1.Type.String(),
        streamId: typebox_1.Type.Number(),
        frameRate: typebox_1.Type.Number(),
        colorFormat: typebox_1.Type.String(),
        interlaceMode: typebox_1.Type.String(),
    })),
});
