"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowCreated = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * Message to emit when a new `show` is * created, serving as both an "audit
 trail event" and a way to keep shows * in sync with site variables.
 */
exports.ShowCreated = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** Name of the created show */
        name: typebox_1.Type.String(),
        /** Identifier of the show which has been created */
        showId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier of the site for which the show was created */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('Show Created'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
