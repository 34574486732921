"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveSiteDistributionChildUpdatedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * The 'LiveSiteDistribution Child Updated' message is intended to inidicate a
 * "child" CloudFront Distribution based upon the `LiveSiteDistribution` has
 * been updated.
 */
exports.LiveSiteDistributionChildUpdatedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * Id of the CloudFront Distribution which was updated based on the
         * `parentDistributionId`. Identifies the "child" Distribution.
         */
        distributionId: typebox_1.Type.String({
            description: 'Id of the "child" CloudFront Distribution',
        }),
        /**
         * Id of the CloudFront Distribution which is used as the base for updating
         * other Distributions. Identifies the "parent" Distribution.
         */
        parentDistributionId: typebox_1.Type.String({
            description: 'Id of the "parent" CloudFront Distribution',
        }),
    }),
    source: message_source_1.LiveSiteViewSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('LiveSiteDistribution Child Updated'),
});
