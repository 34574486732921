"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatChecks = void 0;
const date_1 = require("./json-schema-format-helpers/date");
const date_time_1 = require("./json-schema-format-helpers/date-time");
const email_1 = require("./json-schema-format-helpers/email");
const hostname_1 = require("./json-schema-format-helpers/hostname");
const ipv4_1 = require("./json-schema-format-helpers/ipv4");
const ipv6_1 = require("./json-schema-format-helpers/ipv6");
const regex_1 = require("./json-schema-format-helpers/regex");
const time_1 = require("./json-schema-format-helpers/time");
const uri_1 = require("./json-schema-format-helpers/uri");
const url_1 = require("./json-schema-format-helpers/url");
const uuid_1 = require("./json-schema-format-helpers/uuid");
const formats = [
    'date-time',
    'date',
    'email',
    'hostname',
    'ipv6',
    'ipv4',
    'regex',
    'time',
    'uri',
    'url',
    'uuid',
];
exports.formatChecks = {
    'date-time': (value) => (0, date_time_1.isDateTime)(value, false),
    date: (value) => (0, date_1.isDate)(value),
    email: (value) => (0, email_1.isEmail)(value),
    hostname: (value) => (0, hostname_1.isHostname)(value),
    ipv6: (value) => (0, ipv6_1.isIPv6)(value),
    ipv4: (value) => (0, ipv4_1.isIPv4)(value),
    regex: (value) => (0, regex_1.isRegex)(value),
    time: (value) => (0, time_1.isTime)(value),
    uri: (value) => (0, uri_1.isUri)(value),
    url: (value) => (0, url_1.isUrl)(value),
    uuid: (value) => (0, uuid_1.isUuid)(value),
};
