import {useState, useEffect} from 'react';
import {debounce} from '../../utils';

/**
 * `useAppHeight` sets the current window height as a css variable
 * Usage:
 *    const appHeight = useAppHeight();
 *
 * This hook solves the 100vh issue in iOS Safari, see: https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
 *
 * @returns {number} the height of the application window
 */
export const useAppHeight = (): number => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const setAppHeight = (): void => {
      const windowHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        '--app-height',
        `${windowHeight}px`
      );
      setHeight(windowHeight);
    };

    const [resizeDebounced] = debounce(setAppHeight, 150);
    setAppHeight();

    window.addEventListener('orientationchange', resizeDebounced);
    window.addEventListener('resize', resizeDebounced);

    return () => {
      window.removeEventListener('orientationchange', resizeDebounced);
      window.removeEventListener('resize', resizeDebounced);
    };
  }, []);

  return height;
};
