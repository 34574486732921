"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutputJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const output_model_1 = require("./output-model");
const isOutputItem = (0, json_schema_1.createValidator)(output_model_1.Output);
function fromJson(item, mode) {
    if (isOutputItem(item)) {
        return {
            assetId: item.assetId,
            createdAt: item.createdAt,
            id: item.id,
            metadata: item.metadata,
            type: item.type,
            updatedAt: item.updatedAt,
            uri: item.uri,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isOutputItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Output`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Output`.
 */
function toJson(record) {
    return record;
}
exports.OutputJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
