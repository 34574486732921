"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const module_model_1 = require("./module-model");
const isModuleItem = (0, json_schema_1.createValidator)(module_model_1.ModuleJson);
function attendeeFromJson(item, mode) {
    if (isModuleItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            coreID: item.coreID,
            id: item.id,
            name: item.name,
            path: item.path,
            adminUI: item.adminUI,
            groupID: item.groupID,
            pageID: item.pageID,
            pageIndex: item.pageIndex,
            parentID: item.parentID,
            parentSlotSlug: item.parentSlotSlug,
            parentSlotIndex: item.parentSlotIndex,
            siteID: item.siteID,
            variables: item.variables,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isModuleItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Module`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Module`.
 */
function attendeeToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        coreID: record.coreID,
        id: record.id,
        name: record.name,
        path: record.path,
        adminUI: record.adminUI,
        groupID: record.groupID,
        pageID: record.pageID,
        pageIndex: record.pageIndex,
        parentID: record.parentID,
        parentSlotSlug: record.parentSlotSlug,
        parentSlotIndex: record.parentSlotIndex,
        siteID: record.siteID,
        variables: record.variables,
    };
}
exports.ModuleJsonMapper = {
    fromJSON: attendeeFromJson,
    toJSON: attendeeToJson,
};
