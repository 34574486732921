"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateAssetThumbnailMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
exports.GenerateAssetThumbnailMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        uri: typebox_1.Type.String({
            title: 'URI to generate a thumbnail for',
        }),
        width: typebox_1.Type.Number({
            title: 'Thumbnail width',
            description: 'Thumbnail images are scaled to fit this width.',
        }),
        height: typebox_1.Type.Number({
            title: 'Thumbnail images are scaled to fit this height.',
        }),
    }, {
        description: `Event detail for generating a thumbnail. Provide a thumbnail width and height, and
         images will be scaled to fit this size. If the image is already smaller than the size, reduce its quality instead.`,
    }),
    'detail-type': typebox_1.Type.Literal('GenerateThumbnail'),
    source: message_source_1.AssetManagmentSource,
    traceId: tracing_1.TraceId,
});
