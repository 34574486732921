"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const site_model_1 = require("./site-model");
function fromJSON(item, mode) {
    const isSiteItem = (0, json_schema_1.createValidator)(site_model_1.SiteJson);
    if (isSiteItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            css: item.css,
            meta: item.meta,
            name: item.name,
            organizationId: item.organizationId,
            resources: item.resources,
            settings: item.settings,
            status: item.status,
            variables: item.variables,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isSiteItem.errors ?? [];
    }
}
exports.SiteJsonMapper = {
    fromJSON,
    toJSON(record) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
            id: record.id,
            css: record.css,
            meta: record.meta,
            name: record.name,
            organizationId: record.organizationId,
            resources: record.resources,
            settings: record.settings,
            status: record.status,
            variables: record.variables,
        };
    },
};
