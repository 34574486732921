"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioConfigJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const audio_config_model_1 = require("./audio-config-model");
const isAudioConfigItem = (0, json_schema_1.createValidator)(audio_config_model_1.AudioConfig);
function fromJson(item, mode) {
    if (isAudioConfigItem(item)) {
        return {
            assetId: item.assetId,
            createdAt: item.createdAt,
            description: item.description,
            id: item.id,
            language: item.language,
            modifier: item.modifier,
            slug: item.slug,
            updatedAt: item.updatedAt,
            uri: item.uri,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAudioConfigItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `AudioConfig`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `AudioConfig`.
 */
function toJson(record) {
    return record;
}
exports.AudioConfigJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
