"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestExternalModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const guest_external_service_type_1 = require("./guest-external-service-type");
/**
 * Defines the JSON Schema and the valid shapes for `GuestExternal` records.
 * `GuestExternal` records are intended to represent guests which were
 * authenticated against a third party service.
 */
exports.GuestExternalModel = typebox_1.Type.Object({
    /** The identifier associated with the name & email address pair */
    attendeeId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    /** Avatar assigned to this guest */
    avatar: (0, model_helpers_1.Maybe)(typebox_1.Type.String({
        format: 'uri',
        description: 'The URL of the image to use as a guests avatar',
    })),
    /** Email address of guest from the external service */
    email: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'email' })),
    /** The identifier provided by the external service */
    externalId: typebox_1.Type.String(),
    /** Name of guest from the external service */
    name: typebox_1.Type.String(),
    /** OAuth details related to the external guest */
    oauth: typebox_1.Type.Object({
        /** Token used to access data at `endpoint` */
        accessToken: typebox_1.Type.String(),
        /**
         * Endpoint where the `accessToken` and `refreshToken` may be used to get
         * data
         */
        endpoint: typebox_1.Type.String(),
        /** Timestamp when `accessToken` expires */
        expiresAt: typebox_1.Type.String({ format: 'date-time' }),
        /**
         * Token used to receive a new `accessToken`
         */
        refreshToken: typebox_1.Type.String(),
    }),
    /**
     * Identifies the type of service via which this external guest was verified
     */
    serviceType: guest_external_service_type_1.GuestExternalServiceType,
    /** Identifies the site to which this guest is scoped */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** List of tags associated with this guest */
    tags: typebox_1.Type.Array(typebox_1.Type.String()),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'GuestExternalModel' });
