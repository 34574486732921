import {
  DynamicLayoutComponent,
  type PageData,
  type StateSetter,
} from '@backstage/ui-render';
import {Box} from '@chakra-ui/react';
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  FC,
} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  PageFieldsFragment as PageFields,
  SiteDetailsByDomainQuery,
} from '@backstage/attendee-ui-types';
import {SetSiteData} from './hooks/data/usePageListings/usePageListings';
import {
  DroppabilityMap,
  DropzoneDetailsFromIframe,
  MessagesFromIframe,
  MessagesFromSiteBuilder,
  OverlayDetails,
  SendPositionsChannel,
} from '@backstage/module-tree-shared';
import {defaultOrientation, Orientation} from '@backstage-components/base';

const defaultZ = 20;

const getPosition = (
  element: Element | string | null
): OverlayDetails | void => {
  if (!element) return;

  if (typeof element === 'string') {
    element = document.getElementById(element);
  }

  if (!element) return;

  const {top, left} = element.getBoundingClientRect();

  return {
    id: element.id,
    y: top + window.scrollY,
    x: left + window.scrollX,
    h: element.clientHeight,
    w: element.clientWidth,
  };
};

/**
 * Posts a message to the site-builder. This wrapper is necessary because
 * `postMessage` affords no type safety.
 * @param message
 */
const messageToSiteBuilder = (message: MessagesFromIframe): void => {
  window.parent.postMessage(message, '*');
};

const sendPositions = (
  channel: SendPositionsChannel,
  elementOrId: Element | string
): void => {
  const position = getPosition(elementOrId);
  if (!position) return;

  messageToSiteBuilder({
    channel,
    ...{
      ...position,
      iframeHeight: document.body.scrollHeight,
      iframeWidth: document.body.scrollWidth,
    },
  });
};

const makeRootDropzones = (
  slotEntry: SlotEntry,
  _structure: XMLDocument
): DropzoneDetailsFromIframe[] => {
  const {id, slotName, parent} = slotEntry;
  const children = Array.from(parent.children);
  const base: Omit<DropzoneDetailsFromIframe, 'h' | 'y' | 'i' | 'key'> = {
    id,
    w: document.body.scrollWidth,
    x: 0,
    z: defaultZ,
    slotName,
    axis: 'y',
    offset: 0,
  };
  if (children.length === 0) {
    return [
      {
        ...base,
        h: document.body.scrollHeight,
        y: 0,
        key: 'root',
        i: 0,
      },
    ];
  }
  const thickness = 10;
  const dropzones: DropzoneDetailsFromIframe[] = [
    // Add the topmost dropzone on the page
    {
      ...base,
      h: thickness,
      y: 0,
      key: 'root0',
      i: 0,
      offset: 0,
    },
  ];
  return children.reduce<DropzoneDetailsFromIframe[]>((dropzones, {id}, i) => {
    const child = getPosition(id);
    if (child) {
      dropzones.push({
        ...base,
        id: 'root',
        y: Math.round(child.y + child.h - thickness / 2),
        offset: thickness / 2,
        h: thickness,
        i: i + 1,
        key: `root${i + 1}`,
        z: defaultZ + 80,
      });
    }
    return dropzones;
  }, dropzones);
};

type SlotEntry = {
  id: string;
  slotName: string;
  orientation?: Orientation;
  /** XML Element from `structure` */
  parent: HTMLElement;
};
type DropzoneBase = Pick<
  DropzoneDetailsFromIframe,
  'id' | 'slotName' | 'z' | 'axis' | 'offset'
>;

const getDropzones = (
  droppabilityMap: DroppabilityMap,
  structure: XMLDocument
): DropzoneDetailsFromIframe[] => {
  const slotEntries: SlotEntry[] = [];

  // This reads easier than an Array#reduce
  for (const [id, entry] of Object.entries(droppabilityMap)) {
    const parent = structure.getElementById(id);
    if (!parent) continue;
    for (const slotName in entry) {
      if (entry[slotName]) {
        slotEntries.push({id, slotName, parent});
      }
    }
  }

  const firstPass = slotEntries.reduce<DropzoneDetailsFromIframe[]>(
    (dropzones, slotEntry) => {
      const {id, slotName, parent} = slotEntry;

      /* Root dropzones have special needs */
      if (id === 'root') {
        return dropzones.concat(makeRootDropzones(slotEntry, structure));
      }

      // Determine the depth of the element for the z-index. This won't be perfect due
      // to non-static positioning, but it will usually work.
      let z = defaultZ + 1;
      let node = parent.parentNode;
      while (node) {
        z++;
        node = node.parentNode;
      }

      const children = structure.querySelectorAll(
        `#${id} > [slot="${slotName}"]`
      );
      const parentPosition = getPosition(parent.id);
      if (!parentPosition) return dropzones;

      const orientation =
        parent.getAttribute('orientation') ?? defaultOrientation;
      const axis = orientation === 'horizontal' ? 'x' : 'y';

      const childPositions: OverlayDetails[] = Array.from(children).reduce<
        OverlayDetails[]
      >((a, c) => {
        const position = getPosition(c.id);
        if (position) {
          a.push(position);
        }
        return a;
      }, []);

      // Get base values to make the rest of the calls easier.
      const base: DropzoneBase = {id, slotName, z, axis, offset: 0};

      if (childPositions.length === 0) {
        dropzones.push({
          ...parentPosition,
          ...base,
          i: 0,
          key: id,
          offset: 0,
        });
      } else {
        return dropzones.concat(
          makeDropzones(parentPosition, childPositions, base)
        );
      }
      return dropzones;
    },
    []
  );

  // TODO: Come up with a better way to ensure every dropzone has a non-zero area.
  firstPass.forEach((x) => {
    x.w = Math.max(10, x.w);
    x.h = Math.max(10, x.h);
  });

  return firstPass;
};

/**
 * Takes the parent slot and the children and creates a list of dropzones
 * interspersed before, between, and after the children.
 * @param parentPosition Position and dimension details about the parent element
 * @param childPositions Position and dimension details about the child elements
 * @param base Prepopulated values for the dropzone details
 * @returns
 */
function makeDropzones(
  parentPosition: OverlayDetails,
  childPositions: OverlayDetails[],
  base: DropzoneBase
): DropzoneDetailsFromIframe[] {
  // `xy` and `wh` are terser and easier to reason about than other names
  // like `axis`/`direction` or `magnitude`.
  const {z, id, axis: xy} = base;

  /** `h` or `w` so we can use this function for both axes */
  const wh = xy === 'y' ? 'h' : 'w';

  let lastEnd = parentPosition[xy];
  let lastWH = 0;

  /*
   * Push a bogus zero-width child on the end of the source
   * child array so there's a dropzone at the end.
   */
  childPositions.push({
    ...childPositions[childPositions.length - 1],
    [xy]: parentPosition[xy] + parentPosition[wh],
    [wh]: 0,
  });

  return childPositions.map((cp, i) => {
    // First portion will be add the dropped module before this one.
    const c: DropzoneDetailsFromIframe = {
      ...cp,
      ...base,
      [xy]: lastEnd,
      [wh]: cp[xy] - lastEnd + cp[wh] / 2,
      i,
      z: z + 1,
      key: `${id}-${i}-${z}-a`,
    };
    // This expression can be algebraically simplified, but it's easier to reason about as written.
    // `offset` is the distance between the end of the previous module and the beginning of this one.
    // That's because to only use module centers, the offset could pull towards the larger module.
    c.offset = lastWH / 2 + (c[wh] - lastWH / 2 - cp[wh] / 2) / 2;
    lastEnd = c[xy] + c[wh];
    lastWH = cp[wh];
    return c;
  });
}

let hoveredId: string | void = undefined;
let selectedId: string | void = undefined;

export const PageRouteForEditor: FC<PageRouteForEditorProps> = (props) => {
  const {
    page,
    page: {structure},
    getUpdatedSite,
    isEditMode,
    setIsEditMode,
    setData,
  } = props;
  const modules = page?.modules ?? [];
  const [validIds, setValidIds] = useState<Set<string>>(new Set<string>());

  useEffect(() => {
    setValidIds(
      new Set<string>(
        Array.from(structure.querySelectorAll('*')).map(({id}) => id)
      )
    );
  }, [structure]);

  type MousePositions = Pick<MouseEvent, 'clientX' | 'clientY'>;

  const findHoveredElements = useCallback(
    ({clientX, clientY}: MousePositions, _isOnlyTopmost = false): Element[] => {
      // Get the elements under the provided coordinates
      const els = document.elementsFromPoint(clientX, clientY);
      return els.filter((el) => validIds.has(el.id));
    },
    [validIds]
  );

  useLayoutEffect(() => {
    let hovered: Element | null = null;

    type PageUpdater = (page: PageFields) => PageFields;
    const updatePageInSiteData = (
      data: SiteDetailsByDomainQuery | null | undefined,
      pageId: string,
      callback: PageUpdater
    ): SiteDetailsByDomainQuery | null | undefined => {
      if (!data) return data;
      return {
        ...data,
        site: {
          ...data.site,
          pages: data.site.pages.map((page) => {
            if (page.id !== pageId) return page;
            return callback(page);
          }),
        },
      };
    };

    const windowMessage = (e: MessageEvent<MessagesFromSiteBuilder>): void => {
      const {data} = e;
      switch (data.channel) {
        case 'setEditMode':
          return setIsEditMode(data.editMode ?? false);
        case 'sendSelectedModulePosition':
          {
            const el =
              'id' in data
                ? document.getElementById(data.id)
                : findHoveredElements(data)[0];
            if (!el) {
              selectedId = undefined;
            } else {
              selectedId = el.id;
              sendPositions('selectedModulePosition', el);
            }
          }
          break;
        case 'sendHoveredModulePosition':
          {
            const el =
              'id' in data
                ? document.getElementById(data.id)
                : findHoveredElements(data)[0];
            if (!el) {
              // hoveredId = undefined;
            } else if (el !== hovered) {
              hovered = el;
              hoveredId = el.id;
              sendPositions('moduleHover', el);
            }
          }
          break;
        case 'sendDropZones': {
          messageToSiteBuilder({
            channel: 'dropzoneDetails',
            iframeHeight: document.body.scrollHeight,
            iframeWidth: document.body.scrollWidth,
            dropzones: getDropzones(data.droppabilityMap, structure),
          });

          break;
        }
        case 'editNonGroupModule':
          {
            const pageId = page.id;
            setData((oldData) =>
              updatePageInSiteData(oldData, pageId, (page) => ({
                ...page,
                allCores: page.allCores.map((core) => {
                  if (core.id === data.cid) {
                    core.componentFieldData = data.componentFieldData;
                  }
                  return core;
                }),
              }))
            );
          }
          break;
        case 'handleDroppedNew':
          {
            const pageId = page.id;
            setData((oldData) => {
              const now = new Date().toISOString();
              return updatePageInSiteData(oldData, pageId, (page) => {
                let allComponents = page.allComponents;
                const needsComponent = !allComponents.find(
                  (x) => x.id === data.core.componentId
                );
                if (needsComponent) {
                  allComponents = [
                    ...allComponents,
                    {...data.component, createdAt: now},
                  ];
                }
                return {
                  ...page,
                  allModules: [...page.allModules, data.module],
                  allCores: [...page.allCores, {...data.core, createdAt: now}],
                  allComponents,
                };
              });
            });
          }
          break;
        case 'handleMovedModule':
          {
            const pageId = page.id;
            setData((oldData) => {
              return updatePageInSiteData(oldData, pageId, (page) => {
                const allModules = page.allModules.map((module) => {
                  if (module.id === data.moduleId) {
                    return {...module, ...data.fields};
                  }
                  return module;
                });
                return {...page, allModules};
              });
            });
          }
          break;

        case 'getUpdatedSiteData': {
          getUpdatedSite();
          break;
        }
      }
    };
    window.addEventListener('message', windowMessage);
    return () => {
      window.removeEventListener('message', windowMessage);
    };
  }, [
    findHoveredElements,
    getUpdatedSite,
    page.id,
    setData,
    setIsEditMode,
    structure,
  ]);

  useLayoutEffect(() => {
    const reportWindowSize = (): void => {
      messageToSiteBuilder({
        channel: 'documentLoaded',
        iframeHeight: document.body.scrollHeight,
        iframeWidth: document.body.scrollWidth,
      });
    };
    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      messageToSiteBuilder({
        channel: 'documentLoaded',
        iframeHeight: document.body.scrollHeight,
        iframeWidth: document.body.scrollWidth,
      });
      // TODO: make this event-based instead of making a goofball race condition.
    }, 1000);
  }, []);

  if (selectedId) {
    sendPositions('selectedModulePosition', selectedId);
  }
  if (hoveredId) {
    sendPositions('moduleHover', hoveredId);
  }
  return (
    <Fragment>
      <Helmet htmlAttributes={{'data-pageid': page.id}}>
        <title>{page.title}</title>
        <style>{`html{overflow: hidden;}`}</style>
      </Helmet>
      <Box
        position="fixed"
        backgroundColor="#00f0"
        h={isEditMode ? '100%' : '0'}
        w="100%"
        zIndex={12}
        onClick={(e) => {
          const modules = findHoveredElements(e);
          sendPositions('selectedModulePosition', modules[0]);
        }}
      ></Box>
      <DynamicLayoutComponent components={modules} />
    </Fragment>
  );
};

interface PageRouteForEditorProps {
  showId?: string;
  page: PageData;
  domainName?: string;
  pageFields: PageFields;
  getUpdatedSite: () => void;
  isEditMode: boolean;
  setIsEditMode: StateSetter<boolean>;
  setData: SetSiteData;
}
