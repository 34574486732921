"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteExportJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
exports.SiteExportJson = typebox_1.Type.Object({
    /** Identifier for the instance */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Error string associated with an error status */
    error: typebox_1.Type.Optional(typebox_1.Type.String()),
    /** Request parameters for the export request */
    request: (0, model_helpers_1.JsonObject)(),
    /** Describes where the Object is located in S3 */
    s3Location: typebox_1.Type.Object({
        /** Bucket where the the S3 Object is located */
        bucket: typebox_1.Type.String(),
        /** Key where the S3 Object is located */
        key: typebox_1.Type.String(),
    }),
    /** Identifier for the site to which the session belongs */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** Status of the export */
    status: typebox_1.Type.Union([
        typebox_1.Type.Literal('processing'),
        typebox_1.Type.Literal('complete'),
        typebox_1.Type.Literal('error'),
    ]),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'SiteExportModel' });
