"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryShowInstructions = void 0;
const graphql_tag_1 = require("graphql-tag");
const fragments_1 = require("./fragments");
/**
 * Retrieve `Show` with `showInstructions` by `$environmentId`.
 * @param $environmentId (Required) the `id` of the `Show` to be retrieved
 * @param $sinceInstructionId (Optional) retrieve instructions for `$environmentId`
 * which occurred after `$sinceInstructionId`, if not provided retrieves all
 * instructions.
 */
exports.QueryShowInstructions = (0, graphql_tag_1.gql) `
  query ShowInstructions($environmentId: UUID!, $sinceInstructionId: UUID) {
    environmentById(id: $environmentId) {
      ...ShowWithInstructions
    }
  }
  ${fragments_1.FragmentShowWithInstructions}
`;
