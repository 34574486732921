"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagePrivilege = void 0;
const typebox_1 = require("@sinclair/typebox");
/**
 * Describes the access level an attendee is required to meet before viewing a
 * Page.
 */
exports.PagePrivilege = typebox_1.Type.Union([
    typebox_1.Type.Literal('Public', {
        description: 'A publicly accessible page; no attendee verification required',
    }),
    typebox_1.Type.Literal('Private', {
        description: 'Applies to a page which requires attendee verification before viewing',
    }),
], {
    description: 'Describes the viewer requirements for a Page',
});
