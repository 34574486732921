"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowPublishServiceMessage = exports.ShowGuestMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const show_deleted_1 = require("./show-deleted");
const show_guest_1 = require("./show-guest");
var show_guest_2 = require("./show-guest");
Object.defineProperty(exports, "ShowGuestMessage", { enumerable: true, get: function () { return show_guest_2.ShowGuestMessage; } });
/**
 * Union of any messages produced to or consumed from the Event Bus by
 * `@backstage-services/site-publish`.
 */
exports.ShowPublishServiceMessage = typebox_1.Type.Union([
    show_deleted_1.ShowDeletedMessage,
    ...show_guest_1.ShowGuestMessage.anyOf,
]);
