"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsMediaConvertOutputGroupDetail = void 0;
const typebox_1 = require("@sinclair/typebox");
const VideoDetail = typebox_1.Type.Object({
    heightInPx: typebox_1.Type.Number(),
    widthInPx: typebox_1.Type.Number(),
});
const OutputDetail = typebox_1.Type.Object({
    durationInMs: typebox_1.Type.Optional(typebox_1.Type.Number()),
    outputFilePaths: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    videoDetails: typebox_1.Type.Optional(VideoDetail),
});
const CmafOutputGroupDetail = typebox_1.Type.Object({
    outputDetails: typebox_1.Type.Array(OutputDetail),
    playlistFilePaths: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uri' })),
    type: typebox_1.Type.Literal('CMAF_GROUP'),
});
/**
 * Describes the _observed_ shape of media convert outputs groups with a type of
 * `DASH_ISO_GROUP`
 */
const DashOutputGroupDetail = typebox_1.Type.Object({
    outputDetails: typebox_1.Type.Array(OutputDetail),
    playlistFilePaths: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uri' })),
    type: typebox_1.Type.Literal('DASH_ISO_GROUP'),
});
/**
 * Describes the _observed_ shape of media convert outputs groups with a type of
 * `FILE_GROUP`
 */
const FileOutputGroupDetail = typebox_1.Type.Object({
    outputDetails: typebox_1.Type.Array(typebox_1.Type.Required(OutputDetail)),
    type: typebox_1.Type.Literal('FILE_GROUP'),
});
/**
 * Describes the _observed_ shape of media convert outputs groups with a type of
 * `HLS_GROUP`
 */
const HlsOutputGroupDetail = typebox_1.Type.Object({
    outputDetails: typebox_1.Type.Array(OutputDetail),
    playlistFilePaths: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uri' })),
    type: typebox_1.Type.Literal('HLS_GROUP'),
});
exports.AwsMediaConvertOutputGroupDetail = typebox_1.Type.Union([
    CmafOutputGroupDetail,
    DashOutputGroupDetail,
    FileOutputGroupDetail,
    HlsOutputGroupDetail,
]);
