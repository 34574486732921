"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertType = exports.assertNever = void 0;
/**
 * Asserts that the given object has the `never` type indicating all branches of
 * the union have been consumed.
 * @param input to assert does not exist
 * @throws when executed, this is a runtime enforcement of a type level check.
 */
function assertNever(input) {
    throw new Error(`Received unhandled input ${input}`);
}
exports.assertNever = assertNever;
/**
 * When used with an explicit type parameter enforces with the TypeScript
 * compiler that the given value is of type `T`. This function doesn't do
 * anything aside from helping with compile time assertions.
 * @param T type to be asserted
 * @param _ value to assert has type `T`
 */
const assertType = (_) => void 0;
exports.assertType = assertType;
