"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTransactionJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const email_transaction_model_1 = require("./email-transaction-model");
const isEmailTransactionItem = (0, json_schema_1.createValidator)(email_transaction_model_1.EmailTransactionJson);
function modelFromJson(item, mode) {
    if (isEmailTransactionItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            recipient: item.recipient,
            status: item.status,
            templateId: item.templateId,
            triggerId: item.triggerId,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isEmailTransactionItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `EmailTransaction`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `EmailTransaction`.
 */
function modelToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        recipient: record.recipient,
        status: record.status,
        templateId: record.templateId,
        triggerId: record.triggerId,
    };
}
exports.EmailTransactionJsonMapper = {
    fromJSON: modelFromJson,
    toJSON: modelToJson,
};
