"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransferJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const transfer_model_1 = require("./transfer-model");
const isTransferItem = (0, json_schema_1.createValidator)(transfer_model_1.Transfer);
function fromJson(item, mode) {
    if (isTransferItem(item)) {
        return {
            assetId: item.assetId,
            createdAt: item.createdAt,
            id: item.id,
            transferId: item.transferId,
            transferStatus: item.transferStatus,
            updatedAt: item.updatedAt,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isTransferItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Transfer`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Transfer`.
 */
function toJson(record) {
    return record;
}
exports.TransferJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
