"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidEmail = exports.Email = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../json-schema");
/**
 * JSON Schema definition for emails.
 */
exports.Email = typebox_1.Type.String({ format: 'email' });
/**
 * Function to validate a given value is a email.
 */
exports.isValidEmail = (0, json_schema_1.createValidator)(exports.Email);
