import {defineStyleConfig} from '@chakra-ui/react';

const baseStyle = {
  borderRadius: '0.75rem',
  border: 'none',
  padding: '0.75rem',
  color: '#fff',
  fontWeight: '500',
  fontSize: '0.75rem',
  fontFamily: "'Inter', sans-serif",
};

export const tooltipTheme = defineStyleConfig({baseStyle});
