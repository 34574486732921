"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteExportJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const site_export_model_1 = require("./site-export-model");
function fromJson(item, mode) {
    const isSiteExportItem = (0, json_schema_1.createValidator)(site_export_model_1.SiteExportJson);
    if (isSiteExportItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            error: item.error,
            request: item.request,
            s3Location: {
                bucket: item.s3Location.bucket,
                key: item.s3Location.key,
            },
            siteId: item.siteId,
            status: item.status,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isSiteExportItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `SiteExport`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `SiteExport`.
 */
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        error: record.error,
        request: record.request,
        s3Location: {
            bucket: record.s3Location.bucket,
            key: record.s3Location.key,
        },
        siteId: record.siteId,
        status: record.status,
    };
}
exports.SiteExportJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
