import {useMemo} from 'react';
import {type LocationDetails} from './data/useDomainName';

export function useRouterPrefixes(options: RoutePrefixOptions): RoutePrefixes {
  const {domainName, isPreview, pathShowId, showId} = options;
  const result: RoutePrefixes = useMemo(() => {
    const prefix =
      isPreview && pathShowId === showId && typeof showId === 'string'
        ? `/${domainName}/${pathShowId}` // is preview and showIds match, include both
        : isPreview && (pathShowId !== showId || typeof showId === 'undefined')
          ? `/${domainName}` // is preview and showIds don't match or are both undefined, just domain
          : !isPreview && pathShowId === showId && typeof showId === 'string'
            ? `/${pathShowId}` // not preview and showIds match, just show id
            : ''; // not preview and show id doesn't match no prefix
    const isWithShowId = prefix.includes(`/${showId}`);
    const prefixWithoutShowId = isWithShowId
      ? prefix.replace(`/${showId}`, '')
      : prefix;
    const prefixWithShowId = isWithShowId ? prefix : `${prefix}/${showId}`;
    return {
      router: prefix,
      withShowId: prefixWithShowId,
      withoutShowId: prefixWithoutShowId,
    };
  }, [domainName, isPreview, pathShowId, showId]);
  return result;
}

interface RoutePrefixOptions extends Partial<LocationDetails> {
  pathShowId?: string;
}

interface RoutePrefixes {
  /**
   * Prefix to use with the router, this matches the shape of the current URL
   * regardless of the presence of a show id in it.
   */
  router: string;
  /**
   * The `router` prefix explicitly _with_ the show id.
   */
  withShowId: string;
  /**
   * The `router` prefix explicitly _without_ the show id.
   */
  withoutShowId: string;
}
