"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const asset_model_1 = require("./asset-model");
const isAssetItem = (0, json_schema_1.createValidator)(asset_model_1.Asset);
function fromJson(item, mode) {
    if (isAssetItem(item)) {
        return {
            audioConfigs: item.audioConfigs,
            captionConfigs: item.captionConfigs,
            createdAt: item.createdAt,
            fileName: item.fileName,
            fileSize: item.fileSize,
            fileType: item.fileType,
            id: item.id,
            metadata: item.metadata,
            streams: item.streams,
            transfer: item.transfer,
            transferId: item.transferId,
            type: item.type,
            updatedAt: item.updatedAt,
            uri: item.uri,
            watermarks: item.watermarks,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAssetItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Asset`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Asset`.
 */
function toJson(record) {
    return record;
}
exports.AssetJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
