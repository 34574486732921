"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const account_model_1 = require("./account-model");
function fromJson(item, mode) {
    const isAccountItem = (0, json_schema_1.createValidator)(account_model_1.AccountModel);
    if (isAccountItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            email: item.email,
            preferences: item.preferences,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAccountItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Account`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Account`.
 */
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        email: record.email,
        preferences: record.preferences,
    };
}
exports.AccountJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
