"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEmail = void 0;
// Copied and modified from @sinclair/typebox/format
// See https://github.com/sinclairzx81/typebox/tree/83e05bb43ca79cb22c9efc27e2e16caf7cb6a0e5/example/formats
const Email = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
/**
 * `[ajv-formats]` Internet Email Address [RFC 5321, section 4.1.2.](http://tools.ietf.org/html/rfc5321#section-4.1.2)
 * @example `user@domain.com`
 */
function isEmail(value) {
    return Email.test(value);
}
exports.isEmail = isEmail;
