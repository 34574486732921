"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowInstructionJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const show_instruction_model_1 = require("./show-instruction-model");
const isShowInstructionItem = (0, json_schema_1.createValidator)(show_instruction_model_1.ShowInstructionJson);
function showInstructionFromJson(item, mode) {
    if (isShowInstructionItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            id: item.id,
            kind: item.kind,
            meta: item.meta,
            showId: item.showId,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isShowInstructionItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `ShowInstruction`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `ShowInstruction`.
 */
function showInstructionToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        kind: record.kind,
        meta: record.meta,
        showId: record.showId,
    };
}
exports.ShowInstructionJsonMapper = {
    fromJSON: showInstructionFromJson,
    toJSON: showInstructionToJson,
};
