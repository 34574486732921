"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isServiceType = exports.ServiceType = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../../json-schema");
exports.ServiceType = typebox_1.Type.Union([
    typebox_1.Type.Literal('cognito'),
    typebox_1.Type.Literal('adminDashboard'),
    typebox_1.Type.Literal('apiToken'),
]);
/**
 * Function to validate a given value is a UUID.
 */
exports.isServiceType = (0, json_schema_1.createValidator)(exports.ServiceType);
