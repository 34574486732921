"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const domain_model_1 = require("./domain-model");
function fromJson(item, mode) {
    const isDomainItem = (0, json_schema_1.createValidator)(domain_model_1.Domain);
    if (isDomainItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            id: item.id,
            description: item.description,
            isDefault: item.isDefault,
            name: item.name,
            organizationId: item.organizationId,
            showId: item.showId,
            siteId: item.siteId,
            variables: item.variables,
            verificationStatus: item.verificationStatus,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isDomainItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Domain`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Domain`.
 */
function toJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        description: record.description,
        isDefault: record.isDefault,
        name: record.name,
        organizationId: record.organizationId,
        showId: record.showId,
        siteId: record.siteId,
        variables: record.variables,
        verificationStatus: record.verificationStatus,
    };
}
exports.DomainJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
