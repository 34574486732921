"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stripNonUriChars = void 0;
/**
 * Removes all characters which are not alphanumeric and not `-` or `_` from the
 * given `input`.
 */
function stripNonUriChars(input) {
    const disallowedChars = /[^ a-zA-Z0-9_-]/g;
    return input.replace(disallowedChars, '');
}
exports.stripNonUriChars = stripNonUriChars;
