"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WatermarkAssetMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
exports.WatermarkAssetMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        assetId: typebox_1.Type.String({
            title: 'Asset ID',
            description: 'Asset ID of asset to be watermarked',
        }),
        strength: typebox_1.Type.Number({
            title: 'Watermark Strength',
            description: 'Strength of watermark, 0-100',
        }),
    }),
    'detail-type': typebox_1.Type.Literal('WatermarkAsset'),
    source: message_source_1.AssetManagmentSource,
    traceId: tracing_1.TraceId,
});
