import {GuestConsentProvider} from '@backstage-components/guest-consent-provider';
import {AttendeeProvider} from '@backstage-components/attendee-container';
import type {AppPage} from '@backstage-components/base';
import {ShowInstructionsProvider} from '@backstage-components/base';
import {isLocalhost, isPreviewDomain} from '@backstage/utils/domain-helpers';
import {FC, PropsWithChildren} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {client} from './apollo';
import {config} from './config';
import {
  useAnalytics,
  useFetchInstructions,
  type UseFetchInstructionsOptions,
} from './hooks';
import {JsonValue} from 'type-fest';

const analyticsBlockList: string[] = ['xboxfanfestchallenge.com'];

export const AppContext: FC<PropsWithChildren<AppContextProps>> = ({
  appPages,
  children,
  domainName,
  rootElement,
  showControllerType = 'POLL',
  showId = FALLBACK_SHOW_ID,
  siteVariableLookup,
}) => {
  const instructionFetcher = useFetchInstructions({
    appKey: config.pusherKey,
    cluster: config.pusherCluster,
    showControllerType,
    showId,
  });
  const analytics = useAnalytics(
    typeof domainName === 'string' &&
      analyticsBlockList.some((host) => domainName.endsWith(host))
      ? 'disabled'
      : 'default'
  );
  const location = typeof window === 'undefined' ? undefined : window.location;
  const debugEnabled =
    isPreviewDomain(config, location) || isLocalhost(config, location);

  return (
    <ShowInstructionsProvider
      allowedEmbedOrigins={['*']}
      analyticsEndpoint={analytics?.endpoint}
      analyticsToken={analytics?.token}
      appPages={appPages}
      domainName={domainName ?? 'unknown'}
      rootElement={rootElement}
      fetchInstructions={instructionFetcher}
      showId={showId}
      siteVariableLookup={siteVariableLookup}
      debugEnabled={debugEnabled}
    >
      <AttendeeProvider client={client} showId={showId}>
        <GuestConsentProvider domainName={domainName}>
          <HelmetProvider>{children}</HelmetProvider>
        </GuestConsentProvider>
      </AttendeeProvider>
    </ShowInstructionsProvider>
  );
};

const FALLBACK_SHOW_ID = '00000000-0000-0000-0000-000000000000';

interface AppContextProps
  extends Pick<UseFetchInstructionsOptions, 'showControllerType' | 'showId'> {
  appPages: AppPage[];
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /** Element at the root of the application */
  rootElement?: HTMLElement;
  siteVariableLookup: Record<string, JsonValue>;
}
