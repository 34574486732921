import {Center, Text, VStack} from '@chakra-ui/react';
import {VFC} from 'react';
import {Helmet} from 'react-helmet-async';
import {LCDNotFoundLogo} from '../assets/LCDNotFoundLogo';

export const FourOhFourLcdFallback: VFC<FourOhFourLcdFallbackProps> = (
  props
) => {
  const {message = 'Must have stumbled into the wrong theater'} = props;
  return (
    <Center background="#191919" height="100vh" width="100%">
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <VStack spacing="1.5rem">
        <LCDNotFoundLogo />
        <Text
          color="#fff"
          textAlign="center"
          fontSize={['1rem', '1.6rem']}
          fontWeight={'bold'}
        >
          {message}
        </Text>
      </VStack>
    </Center>
  );
};

interface FourOhFourLcdFallbackProps {
  /**
   * @default Must have stumbled into the wrong theater
   */
  message?: string;
}
