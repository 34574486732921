"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/** Defines the JSON serialized shape of component records. */
const ComponentJson = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: (0, model_helpers_1.Nullable)(typebox_1.Type.String()),
    reactName: typebox_1.Type.String(),
    version: typebox_1.Type.Number(),
    defaultFieldData: (0, model_helpers_1.JsonValue)(),
    slug: typebox_1.Type.String(),
    slotConfiguration: (0, model_helpers_1.JsonValue)(),
    tags: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    isRestricted: (0, model_helpers_1.Nullable)(typebox_1.Type.Boolean()),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ComponentModel' });
/** Defines the JSON schema of component records */
exports.Component = ComponentJson;
