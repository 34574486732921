"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteExportMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("./message-source");
const tracing_1 = require("./tracing");
const SiteExportCreateMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** S3 Bucket name where export will be stored */
        bucket: typebox_1.Type.String({}),
        /** Id of the export */
        exportId: typebox_1.Type.String({ format: 'uuid' }),
        /** S3 Object key where export will be stored */
        key: typebox_1.Type.String({}),
        /** Indicates the information from pages to include in the export */
        pageRequest: typebox_1.Type.Optional(typebox_1.Type.Object({
            /** Whether the modules and components are included in the page data */
            components: typebox_1.Type.Optional(typebox_1.Type.Boolean({})),
            /** Whether the flows and flowtabs are included in the page data */
            flows: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
        })),
        /** Id of the site to be exported */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
        /** Indicates the information from site to include in the export */
        siteRequest: typebox_1.Type.Object({
            /** Whether the `meta` properties of the site are included */
            meta: typebox_1.Type.Optional(typebox_1.Type.Boolean({})),
            /** Whether the saved instructions of the site are included */
            instructions: typebox_1.Type.Optional(typebox_1.Type.Boolean({})),
            /** Whether the `resource` properties of the site are included */
            resources: typebox_1.Type.Optional(typebox_1.Type.Boolean({})),
        }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Site Export Create'),
}, { $id: 'SiteExportCreateMessage' });
const SiteExportErrorMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** S3 Bucket name where export will be stored */
        bucket: typebox_1.Type.String({}),
        /** Id of the export */
        exportId: typebox_1.Type.String({ format: 'uuid' }),
        /** S3 Object key where export will be stored */
        key: typebox_1.Type.String({}),
        /** The reason for the failure */
        reason: typebox_1.Type.String({}),
        /** Id of the site to be exported */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Site Export Error'),
}, { $id: 'SiteExportErrorMessage' });
const SiteExportSuccessMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** S3 Bucket name where export will be stored */
        bucket: typebox_1.Type.String({}),
        /** Id of the export */
        exportId: typebox_1.Type.String({ format: 'uuid' }),
        /** S3 Object key where export will be stored */
        key: typebox_1.Type.String({}),
        /** Id of the site to be exported */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Site Export Success'),
}, { $id: 'SiteExportSuccessMessage' });
exports.SiteExportMessage = typebox_1.Type.Union([SiteExportCreateMessage, SiteExportErrorMessage, SiteExportSuccessMessage], { $id: 'SiteExportMessage' });
