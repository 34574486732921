"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowDeletedMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * The 'Show Deleted' message is intended to be emitted when a show is removed
 * from the primary database.
 */
exports.ShowDeletedMessage = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        showId: typebox_1.Type.String({ description: 'Id of the show which has been deleted' }),
        siteId: typebox_1.Type.String({
            description: 'Id of the site to which the deleted show belonged',
        }),
    }),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
    'detail-type': typebox_1.Type.Literal('Show Deleted'),
});
