"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteSettings = void 0;
const typebox_1 = require("@sinclair/typebox");
const shared_1 = require("../../shared");
const site_analytics_filter_schema_1 = require("./site-analytics-filter-schema");
exports.SiteSettings = typebox_1.Type.Object({
    analyticsDashboard: typebox_1.Type.Optional(site_analytics_filter_schema_1.SiteAnalyticsFilterSchema),
    /**
     * List of record name and value pairs for validating certificate associated
     * with the site
     */
    certificateVerification: typebox_1.Type.Optional(typebox_1.Type.Array(shared_1.CertificateVerificationRecord)),
    /** Domain name of the CloudFront Distribution associated with the site */
    distributionDomainName: typebox_1.Type.Optional(typebox_1.Type.String()),
    /** Type of show controller to use for the site */
    showController: typebox_1.Type.Union([
        typebox_1.Type.Literal('REAL_TIME', {
            title: 'Real-Time Show Controller',
            description: 'Receive instructions via push',
        }),
        typebox_1.Type.Literal('POLL', {
            title: 'Polling Show Controller',
            description: 'Receive instructions by polling for updates',
        }),
    ]),
}, { $id: 'SiteSettingsModel' });
