"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestExternalServiceType = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.GuestExternalServiceType = typebox_1.Type.Union([
    typebox_1.Type.Literal('oneid', {
        description: `Indicates the canonical Guest record exists in the Disney OneID service`,
    }),
], {
    $id: 'GuestExternalServiceType',
});
