"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteVariableJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const site_variable_model_1 = require("./site-variable-model");
function fromJson(item, mode) {
    const isSiteVariableItem = (0, json_schema_1.createValidator)(site_variable_model_1.SiteVariable);
    if (isSiteVariableItem(item)) {
        const base = {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON(item),
            adminUI: item.adminUI,
            description: item.description,
            id: item.id,
            name: item.name,
            siteId: item.siteId,
            sortOrder: item.sortOrder,
            slug: item.slug,
        };
        // This `switch` is odd but is necessary for TypeScript to determine that
        // `kind` and `value` can be matched up. A type assertion could be used
        // instead but the type assertion then hides type errors when `SiteVariable`
        // definition changes.
        switch (item.kind) {
            case 'color':
                return { ...base, kind: item.kind, value: item.value };
            case 'date':
                return { ...base, kind: item.kind, value: item.value };
            case 'image':
                return { ...base, kind: item.kind, value: item.value };
            case 'link':
                return { ...base, kind: item.kind, value: item.value };
            case 'number':
                return { ...base, kind: item.kind, value: item.value };
            case 'rich_text':
                return { ...base, kind: item.kind, value: item.value };
            case 'show_variable':
                return { ...base, kind: item.kind, value: item.value };
            case 'switch':
                return { ...base, kind: item.kind, value: item.value };
            case 'text':
                return { ...base, kind: item.kind, value: item.value };
            case 'video':
                return { ...base, kind: item.kind, value: item.value };
        }
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isSiteVariableItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `SiteVariable`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `SiteVariable`.
 */
function toJson(record) {
    const base = {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        adminUI: record.adminUI,
        description: record.description,
        id: record.id,
        name: record.name,
        siteId: record.siteId,
        sortOrder: record.sortOrder,
        slug: record.slug,
    };
    // This `switch` is odd but is necessary for TypeScript to determine that
    // `kind` and `value` can be matched up. A type assertion could be used
    // instead but the type assertion then hides type errors when `SiteVariable`
    // definition changes.
    switch (record.kind) {
        case 'color':
            return { ...base, kind: record.kind, value: record.value };
        case 'date':
            return { ...base, kind: record.kind, value: record.value };
        case 'image':
            return { ...base, kind: record.kind, value: record.value };
        case 'link':
            return { ...base, kind: record.kind, value: record.value };
        case 'number':
            return { ...base, kind: record.kind, value: record.value };
        case 'rich_text':
            return { ...base, kind: record.kind, value: record.value };
        case 'switch':
            return { ...base, kind: record.kind, value: record.value };
        case 'text':
            return { ...base, kind: record.kind, value: record.value };
        case 'video':
            return { ...base, kind: record.kind, value: record.value };
        case 'show_variable':
            return { ...base, kind: record.kind, value: record.value };
    }
}
exports.SiteVariableJsonMapper = {
    fromJSON: fromJson,
    toJSON: toJson,
};
