"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuestSession = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const guest_external_service_type_1 = require("../guest-external/guest-external-service-type");
const GuestSessionBase = typebox_1.Type.Object({
    /** Identifier for the instance */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Domain from which the authentication request originated */
    domainName: typebox_1.Type.String(),
    /** Identifier for the show to which the session belongs */
    showId: typebox_1.Type.String({ format: 'uuid' }),
    ...timestamp_json_1.TimestampsJson.properties,
});
const GuestSessionAccessCode = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Identifier for the `guest` record associated with the session */
        accessCodeId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier for the `attendee` record associated with the session */
        attendeeId: typebox_1.Type.String({ format: 'uuid' }),
    }),
]);
const GuestSessionExternal = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Identifier for the `guest` record associated with the session */
        externalId: typebox_1.Type.String(),
        /** Identifies the external service used to verify the guest */
        serviceType: guest_external_service_type_1.GuestExternalServiceType,
        /** Name provided by the guest */
        name: typebox_1.Type.String(),
        /** Email address, if provided by the guest */
        email: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'email' })),
    }),
]);
const GuestSessionOpen = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Name provided by the guest */
        name: typebox_1.Type.String(),
        /** Email address, if provided by the guest */
        email: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'email' })),
        /** Whether the guest agreed to the provided text during authentication */
        agreementAnswer: typebox_1.Type.Boolean(),
        /** Text to which the guest agreed (or not) during authentication */
        agreementText: typebox_1.Type.String(),
    }),
]);
const GuestSessionPassCode = typebox_1.Type.Intersect([
    GuestSessionBase,
    typebox_1.Type.Object({
        /** Name associated with the guest */
        name: typebox_1.Type.String(),
        /** Identifier for the passcode used for authentication. */
        passcodeId: typebox_1.Type.String(),
    }),
]);
exports.GuestSession = typebox_1.Type.Union([
    GuestSessionAccessCode,
    GuestSessionExternal,
    GuestSessionOpen,
    GuestSessionPassCode,
], { $id: 'GuestSessionModel' });
