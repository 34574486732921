"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttendeeJsonMapper = void 0;
const json_schema_1 = require("../../json-schema");
const timestamp_json_1 = require("../timestamp-json");
const attendee_model_1 = require("./attendee-model");
const isAttendeeItem = (0, json_schema_1.createValidator)(attendee_model_1.AttendeeJson);
function attendeeFromJson(item, mode) {
    if (isAttendeeItem(item)) {
        return {
            ...timestamp_json_1.TimestampsJsonMapper.fromJSON('createdAt' in item ? item : {}),
            email: item.email,
            id: item.id,
            name: item.name,
        };
    }
    else if (mode === 'data-only') {
        return undefined;
    }
    else {
        return isAttendeeItem.errors ?? [];
    }
}
/**
 * Create a JSON representation of a `Attendee`.
 * @param record to convert to JSON
 * @returns a JSON represenation of `Attendee`.
 */
function attendeeToJson(record) {
    return {
        ...timestamp_json_1.TimestampsJsonMapper.toJSON(record),
        id: record.id,
        name: record.name,
        email: record.email,
    };
}
exports.AttendeeJsonMapper = {
    fromJSON: attendeeFromJson,
    toJSON: attendeeToJson,
};
