"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Domain = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/** Defines the JSON serialized shape of domain records. */
const DomainJson = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.String()),
    showId: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    organizationId: typebox_1.Type.String({ format: 'uuid' }),
    variables: (0, model_helpers_1.Nullable)((0, model_helpers_1.JsonValue)()),
    verificationStatus: typebox_1.Type.Union([
        typebox_1.Type.Literal('initial'),
        typebox_1.Type.Literal('certificatePending'),
        typebox_1.Type.Literal('certificateValidated'),
        typebox_1.Type.Literal('completed'),
    ]),
    isDefault: typebox_1.Type.Boolean(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'DomainModel' });
/** Defines the JSON schema of domain records */
exports.Domain = DomainJson;
