"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentInstanceJson = exports.createContentInstanceModel = exports.ContentInstanceModel = exports.ContentInstanceStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const slug_1 = require("../../data/slug");
const model_helpers_1 = require("../model-helpers");
const helpers_1 = require("../../helpers");
exports.ContentInstanceStatus = (0, helpers_1.StringEnum)(['draft', 'published'], {
    title: 'Status',
});
/**
 * Some properties common to content instances. This could have been a `TObject`
 * and used with `Type.Composite` to create the `ContentInstanceModel` but the
 * definition was unnecessarily difficult when making a generic type for a function.
 * @see createContentInstanceModel
 */
const contentInstanceSharedProperties = {
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String({ title: 'Item Name' }),
    slug: typebox_1.Type.String({ pattern: slug_1.slugPattern, title: 'Item Slug (url portion)' }),
    contentModelId: typebox_1.Type.String({ format: 'uuid' }),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    status: exports.ContentInstanceStatus,
};
/**
 * Defines the JSON serialized shape of the data records.
 */
exports.ContentInstanceModel = typebox_1.Type.Object({
    ...contentInstanceSharedProperties,
    value: (0, model_helpers_1.JsonObject)(),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ContentInstanceModel' });
/**
 * Creates the detailed model for a content instance. This model includes a detailed
 * type for the `value` property instead of the generic `JsonValue`.
 * @param value The TypeBox type for the `value` property of `ContentInstanceModel`
 * @param key A key for this model's type. It's advised to use the `contentModelId`
 * @returns
 */
const createContentInstanceModel = (value, key) => typebox_1.Type.Object({ ...contentInstanceSharedProperties, value }, { $id: `ContentInstanceModel_${key}` });
exports.createContentInstanceModel = createContentInstanceModel;
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * the data records.
 */
exports.ContentInstanceJson = exports.ContentInstanceModel;
