"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Output = void 0;
const typebox_1 = require("@sinclair/typebox");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
/**
 * Matches the `output_type_check` constraint on the `type` column of the
 * `output` table.
 */
const OutputType = typebox_1.Type.Union([
    typebox_1.Type.Literal('dash'),
    typebox_1.Type.Literal('hls'),
    typebox_1.Type.Literal('thumb'),
]);
const OutputMetadata = typebox_1.Type.Union([
    // For video types (dash, hls)
    typebox_1.Type.Object({ durationInMs: typebox_1.Type.Number() }),
    // For image types (thumb)
    typebox_1.Type.Object({ height: typebox_1.Type.Number(), width: typebox_1.Type.Number() }),
    // When other details couldn't be extracted
    typebox_1.Type.Null(),
]);
/**
 * Defines the JSON serialized shape of `output` data records related to `asset`
 * records.
 */
exports.Output = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    assetId: typebox_1.Type.String({ format: 'uuid' }),
    metadata: OutputMetadata,
    type: OutputType,
    uri: typebox_1.Type.String({ format: 'uri' }),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
