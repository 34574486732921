"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentModelJson = exports.ContentModelModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const model_helpers_1 = require("../model-helpers");
const slug_1 = require("../../data/slug");
/**
 * Defines the JSON serialized shape of the data records.
 */
exports.ContentModelModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    name: typebox_1.Type.String(),
    slug: typebox_1.Type.String({ pattern: slug_1.slugPattern }),
    description: typebox_1.Type.String(),
    adminUI: (0, model_helpers_1.JsonValue)(),
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'ContentModelModel' });
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * the data records.
 */
exports.ContentModelJson = exports.ContentModelModel;
