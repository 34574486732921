"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimestampsJsonMapper = exports.TimestampsJson = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../../json-schema");
/**
 * Defines the `createdAt` and `updatedAt` model columns as timestamps in JSON
 * schema when they are number columns.
 */
exports.TimestampsJson = typebox_1.Type.Object({
    createdAt: typebox_1.Type.Integer({ description: 'Number of _seconds_ since epoch' }),
    updatedAt: typebox_1.Type.Integer({ description: 'Number of _seconds_ since epoch' }),
});
/**
 * Maps Timestamps to TimestampsJson and back again. For models which do not
 * require the more conditional handling of
 */
exports.TimestampsJsonMapper = {
    fromJSON: timestampsFromJson,
    toJSON: timestampsToJson,
};
const isTimestampsItem = (0, json_schema_1.createValidator)(exports.TimestampsJson);
/**
 * Extract timestamp data out of the given object which may have new style
 * (`createdAt` and `updatedAt`) or old style (`createDate`) timestamps. If
 * `updatedAt` isn't provided the creation date will be used as the updated
 * date. If neither are provided the "current" time will be used.
 * @param item from which timestamps will be extracted
 * @returns timestamps extracted from `item`
 */
function timestampsFromJson(item) {
    if (!isTimestampsItem(item)) {
        return { createdAt: new Date(), updatedAt: new Date() };
    }
    else {
        return {
            createdAt: typeof item.createdAt === 'number'
                ? new Date(item.createdAt)
                : new Date(),
            updatedAt: typeof item.updatedAt === 'number'
                ? new Date(item.updatedAt)
                : new Date(),
        };
    }
}
/**
 * Format the timestamps of `item` consistent with the format expected by
 * `TimestampsJson`
 * @param item whose timestamps will be formatted as strings
 * @returns JSON formatted timestamps
 */
function timestampsToJson(item) {
    return {
        createdAt: Math.floor(item.createdAt.getTime() / 1000),
        updatedAt: Math.floor(item.updatedAt.getTime() / 1000),
    };
}
