"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidUuid = exports.Uuid = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../json-schema");
/**
 * JSON Schema definition for UUIDs.
 */
exports.Uuid = typebox_1.Type.String({ format: 'uuid' });
/**
 * Function to validate a given value is a UUID.
 */
exports.isValidUuid = (0, json_schema_1.createValidator)(exports.Uuid);
